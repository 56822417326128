import { HttpClient } from '@angular/common/http';
import { Injectable, inject, Signal, signal, computed } from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { catchError, combineLatestWith, distinctUntilChanged, map, mergeWith, of, scan, shareReplay, startWith, switchMap } from 'rxjs';

import { StationTimeSeries, TimeSeriesItem } from '../models/time-series.models';
import { MeasurementRxStompService } from './stomp/measurements-stomp.service';
import { END_INDEX, Heading, MET_GARDEN_INDEX, MIDDLE_INDEX, Runway, TOUCHDOWN_INDEX } from '../models/runway.models';
import { Measurement, MeasurementConfig, PERIOD, PROCESS } from '../models/measurements.models';
import { API_URL } from 'src/environments/url';
import { SENSOR, StationId } from '../models/stations.models';
import { ColumnData, WindRoseData } from '../models/wind-rose.models';
import { RunwaysService } from './runways.service';
import { parseMeasurementValueToNumberValue } from '../utilities/measurement-utilities';
import { MeasurementsService, DEFAULT_MEASUREMENT_CONFIG } from './measurements.service';

type AwosWindRoseDataStream = {
  type: 'WINDROSE',
  windRoseData: Array<ColumnData & { stationId: StationId }>,
}

@Injectable({
  providedIn: 'root'
})
export class AwosService {

  http = inject(HttpClient);
  #wsService = inject(MeasurementRxStompService);
  #runwaysService = inject(RunwaysService);
  #measurementsService = inject(MeasurementsService);

  #switchActiveHeading$ = signal<Heading | undefined>(undefined);

  runways = toSignal(this.#runwaysService.runways$);
  activeRunwayId = signal<string>('');
  activeRunwayViewModel$ = computed(() => {
    const initialRunway: Runway = {
      id: '',
      heading: {
        name: '',
        value: 0,
      },
      name: '',
      stations: {},
    }
   if (this.activeRunwayId() !== ''){
    return this.runways()?.find(runway => runway.id === this.activeRunwayId()) || initialRunway;
   } else {
    const runways = this.runways();
    return runways && runways.length ? runways[0] : initialRunway;
   }
  });

  #stationsId$ = toObservable(this.activeRunwayViewModel$).pipe(
    map(runway => runway ?  Object.values(runway.stations) as StationId[] : new Array<StationId>()),
    distinctUntilChanged((prevIds, currIds) => {
      return prevIds.length === currIds.length && prevIds.every(id => currIds.includes(id));
    }),
    shareReplay(1),
  );

  activeHeading = computed(() => {
    if (this.#switchActiveHeading$()){
      return this.#switchActiveHeading$();
    } else if (this.activeRunwayViewModel$()) {
        return this.activeRunwayViewModel$().heading;
    } else {
        return { name: '', value: 0 } as Heading; 
    }
  });

  switchActiveHeading(newActiveHeading: Heading) {
    this.#switchActiveHeading$.set(newActiveHeading);
  }

  switchRunway(id: string) {
    this.activeRunwayId.set(id);
  }

  #awosMeasurements$ = this.#stationsId$.pipe(
    switchMap(stationIds => {
      const afterTimestamp = new Date();
      afterTimestamp.setSeconds(afterTimestamp.getSeconds() - 80);
      const config: MeasurementConfig = {
        ...DEFAULT_MEASUREMENT_CONFIG,
        stationId: [...stationIds],
        frequency: [PERIOD.PT1M, PERIOD.PT10M, PERIOD.PT2M, PERIOD.PT30S, PERIOD.PT10S, PERIOD.PT1H, PERIOD.PT24H],
        afterTimestamp,
      }
      return this.#measurementsService.getLastMeasurements(config)
    }),
    shareReplay(1),
  );

  awosMeasurements = toSignal(this.#awosMeasurements$, { initialValue: new Array<Measurement>() });

  windSpeedTimeSeries$: Signal<StationTimeSeries[] | undefined> = toSignal(
    this.#stationsId$.pipe(
      switchMap(stationsIds => {
        const afterTimestamp = new Date();
        afterTimestamp.setHours(afterTimestamp.getHours() - 3);
        const config: MeasurementConfig = {
          ...DEFAULT_MEASUREMENT_CONFIG,
          afterTimestamp,
          stationId: stationsIds,
          sensorType: [SENSOR.WIND_SPEED],
          frequency: [PERIOD.PT1M],
          process: [PROCESS.AVERAGE],
        };
        return this.#measurementsService.getMeasurements(config).pipe(
          scan((accumulator: Map<StationId, StationTimeSeries>, measurements: Array<Measurement>) => {
            measurements.forEach(measurement => {
              if (measurement.frequency === PERIOD.PT1M && measurement.process === PROCESS.AVERAGE && measurement.sensorType === SENSOR.WIND_SPEED) {
                const key = measurement.stationId;
                const item = accumulator.get(key) || { stationId: measurement.stationId, timeSeries: new Array<TimeSeriesItem>() };
                const timeSeriesItem: TimeSeriesItem = [new Date(measurement.timestamp).getTime(), parseMeasurementValueToNumberValue(measurement.value)];
                item.timeSeries.push(timeSeriesItem);
                accumulator.set(key, item);
              }
            });
            return accumulator;
          }, new Map<StationId, StationTimeSeries>()),    
        );
      }),
      map((accumulator: Map<StationId, StationTimeSeries>) => [...accumulator.values()]),
      takeUntilDestroyed(),
    ), { initialValue: new Array<StationTimeSeries>() });

  #windRoseInitialDatas$ = this.http.get<AwosWindRoseDataStream>(API_URL.WIND_ROSE);
  #windRoseRealTimeDatas$ = this.#wsService.connect<AwosWindRoseDataStream>('WINDROSE');

  #windRose$ = this.#windRoseInitialDatas$.pipe(
    mergeWith(this.#windRoseRealTimeDatas$),
    map(data => data.windRoseData),
    startWith(new Array<ColumnData & { stationId: StationId }>()),
    catchError(() => of(new Array<ColumnData & { stationId: StationId }>())),
    map(windRoseData => {
      const result = windRoseData.reduce((accumulateur, currentValue) => {
        const windRoseColumnData: ColumnData = {
          id: currentValue.id,
          name: currentValue.name,
          data: currentValue.data,
        };
        accumulateur.get(currentValue.stationId)?.windRoseData.push(windRoseColumnData)
          || accumulateur.set(currentValue.stationId, { stationId: currentValue.stationId, windRoseData: [windRoseColumnData] });
        return accumulateur;
      }, new Map<string, WindRoseData>());
      return [...result.values()];
    }),
    startWith(new Array<WindRoseData>()),
    shareReplay(1),
  );

  windRoseTouchdown$ = this.#windRose$.pipe(
    combineLatestWith(toObservable(this.activeRunwayViewModel$)),
    map(([windRoseDataList, runway]) =>
      windRoseDataList.find(windRoseData => windRoseData.stationId === runway.stations[TOUCHDOWN_INDEX])
    ),
  );

  windRoseMiddle$ = this.#windRose$.pipe(
    combineLatestWith(toObservable(this.activeRunwayViewModel$)),
    map(([windRoseDataList, runway]) =>
      windRoseDataList.find(windRoseData => windRoseData.stationId === runway.stations[MIDDLE_INDEX])
    ),
  );
  windRoseRollout$ = this.#windRose$.pipe(
    combineLatestWith(toObservable(this.activeRunwayViewModel$)),
    map(([windRoseDataList, runway]) =>
      windRoseDataList.find(windRoseData => windRoseData.stationId === runway.stations[END_INDEX])
    ),
  );
  windRoseDataMetGarden$ = this.#windRose$.pipe(
    combineLatestWith(toObservable(this.activeRunwayViewModel$)),
    map(([windRoseDataList, runway]) =>
      windRoseDataList.find(windRoseData => windRoseData.stationId === runway.stations[MET_GARDEN_INDEX])
    ),
  );

}

