<div class="toolbar">
  <mat-toolbar>
    <a routerLink="/map" routerLinkActive="active-link" class="dipla-logo">
      <img class="dipla-img" width="30" height="30" ngSrc="../assets/img/dipla-logo.svg" alt="DIPla">
      <span>DI</span><span class="logo">Pla</span>
    </a>
    <nav mat-tab-nav-bar [tabPanel]="tabPanel">
      <a mat-tab-link routerLink="/map" routerLinkActive="active-link">Maps</a>
      <a routerLinkActive="active-link" mat-tab-link routerLink="/alerts">Alerts
        <span class="dipla-badge" [matBadgeHidden]="alertsCount() === 0" [matBadge]="alertsCount() > 99 ? '⚠' : alertsCount()" matBadgeOverlap="true"
          matBadgeColor="warn"></span>
      </a>
      <!-- <a mat-tab-link routerLink="/map">Compare</a>
      <a mat-tab-link routerLink="/map">Exports</a> -->
      <a mat-tab-link routerLink="/awos" routerLinkActive="active-link">AWOS</a>
      <a mat-tab-link routerLink="/stations" routerLinkActive="active-link">Stations</a>
    </nav>
    <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
    <span class="spacer"></span>
    @if (logoClientEnabled()) {
      <a href="http://www.met.gov.bn/" target="blank">
        <img class="client-logo" src="../assets/img/client-logo.png" alt="BDMD">
      </a>
    }
    <dipla-user-authentication [user]="user()" />
  </mat-toolbar>
</div>
