<mat-sidenav-container class="wrapper">
  <mat-sidenav mode="side" [(opened)]="openedNav" class="sidenav">
    @if (runways() && runways()?.length) {
      <div class="runway-nav">
        <h3>Runways</h3>
      @for (currentRunway of runways(); track $index) {
          <button class="runway-nav-item" [ngClass]="{'active': runway().id === currentRunway.id}" (click)="onSelectedRunway(currentRunway)">
            <img src="../assets/img/runway.svg" alt="runway" />
            <span>{{currentRunway.name || currentRunway.id}}</span>
          </button>
        }
      </div>
    }
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-sidenav-container class="wrapper">
      <mat-sidenav mode="side" [(opened)]="openedMetGarden" position="end" class="side-met-garden">
        @if (runway() && runway().oppositeHeading && runway().heading){
          @if (activeHeading(); as activeHeading) {
            <h2 tabindex="0" class="title">Met Garden</h2>
            <dipla-card-met-garden [data]="metGardenMeasurements()"
              [runwayConfig]="{heading: activeHeading, backgroundImage: './assets/img/runway_'+activeHeading.name+'.svg'}"
            />
            <div class="card-runway">
              @if (windRoseDataMetGarden$ | async; as windRoseDataMetGarden) {
              <dipla-card-wind-rose [data]="windRoseDataMetGarden" position="left"
                title="Wind rose" />
              }
            </div>
          }
        }
      </mat-sidenav>
      <mat-sidenav-content>
        <div class="sidenav-content">
          <div class="toggle-nav" #navTooltip="ngbTooltip" [ngbTooltip]="openedNav() ? 'Hide Menu' : 'Display Menu'" tooltipClass="custom-tooltip-class" [openDelay]="200">
            <button type="button" class="btn-sidenav" mat-mini-fab aria-label="expand sidenav" (click)="onOpenedNav(); navTooltip.close()">
              <mat-icon>{{sidenavIcon()}}</mat-icon>
            </button>
          </div>
          <div class="toggle-met-garden" #metGardenTooltip="ngbTooltip" [ngbTooltip]="openedMetGarden() ? 'Hide MetGarden' : 'Display MetGarden'" tooltipClass="custom-tooltip-class" [openDelay]="200" [closeDelay]="400">
            <button type="button" class="btn-sidenav" mat-mini-fab aria-label="expand sidenav" (click)="onOpenedMetGarden(); metGardenTooltip.close()">
              <mat-icon>{{sideMetGardenIcon()}}</mat-icon>
            </button>
          </div>
          <div class="main">
            @if (runway() && runway().oppositeHeading && runway().heading){
              @if (activeHeading(); as activeHeading) {
              <div class="cards-runway">
                <div class="card-runway">
                  <h2 tabindex="0" class="title"
                    [ngClass]="activeHeading.name === runway().heading.name ? 'title-active-runway' : 'title-unactive-runway'"
                    (click)="onSwitchActiveRunway(runway().heading)" (keyup.enter)="onSwitchActiveRunway(runway().heading)">
                    {{
                    runway().heading.name }}</h2>
                  <dipla-card-runway [data]="stationOneMeasurements()"
                    [runwayConfig]="{heading: activeHeading, backgroundImage: './assets/img/runway_'+activeHeading.name+'.svg'}"
                    position="left" />
                </div>
                <div class="card-runway">
                  <h2 class="title">Middle</h2>
                  <dipla-card-runway [data]="stationTwoMeasurements()" position="middle"
                    [runwayConfig]="{heading: activeHeading, backgroundImage: './assets/img/runway_'+activeHeading.name+'.svg'}" />
                </div>
                <div class="card-runway">
                  <h2 tabindex="0" class="title"
                    [ngClass]="activeHeading.name === runway().oppositeHeading?.name ? 'title-active-runway' : 'title-unactive-runway'"
                    (click)="onSwitchActiveRunway(runway().oppositeHeading || runway().heading)"
                    (keyup.enter)="onSwitchActiveRunway(runway().heading)">{{ runway().oppositeHeading?.name }}</h2>
                  <dipla-card-runway [data]="stationThreeMeasurements()"
                    [runwayConfig]="{heading: activeHeading, backgroundImage: './assets/img/runway_'+activeHeading. name+'.svg'}"
                    position="right" />
                </div>
              </div>
              <div class="cards-runway-wind-rose">
                <div class="card-runway">
                  @if (windRoseDataTouchdown$ | async; as windRoseDataTouchdown) {
                  <dipla-card-wind-rose [data]="windRoseDataTouchdown" position="left"
                    [title]="'Wind rose ' + runway().heading.name" />
                  }
                </div>
                <div class="card-runway">
                  @if (windRoseDataMiddle$ | async; as windRoseDataMiddle) {
                  <dipla-card-wind-rose [data]="windRoseDataMiddle" position="middle" title="Wind rose middle" />
                  }
                </div>
                <div class="card-runway">
                  @if (windRoseDataRollout$ | async; as windRoseDataRollout) {
                  <dipla-card-wind-rose [data]="windRoseDataRollout" position="right"
                    [title]="'Wind rose ' + runway().oppositeHeading?.name" />
                  }
                </div>
              </div>
      
              <div>
                <div class="col-sm-12">
                  @if (windSpeedTimeSeries(); as windSpeedTimeSeries) {
                  <dipla-card-wind-speed-time-series [data]="windSpeedTimeSeries" />
                  }
                </div>
              </div>
              }
            }
          </div>
        </div>
    
      </mat-sidenav-content>
    </mat-sidenav-container>
  </mat-sidenav-content>
</mat-sidenav-container>