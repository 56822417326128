import { Component, computed, input } from '@angular/core';

import { EChartsOption } from 'echarts';
import { StationTimeSeries } from 'src/app/shared/models/time-series.models';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { compareTimeSeriesFunction } from 'src/app/shared/utilities/measurement-utilities';

@Component({
  selector: 'dipla-card-wind-speed-time-series',
  standalone: true,
  imports: [NgxEchartsDirective],
  templateUrl: './card-wind-speed-time-series.component.html',
  styleUrls: ['./card-wind-speed-time-series.component.scss'],
  providers: [
    provideEcharts(),
  ],
})
export class CardWindSpeedTimeSeriesComponent {

  data = input.required<Array<StationTimeSeries>>();
  title = input<string>("Wind speed");

  options: EChartsOption = this.getInitOptions();
  updatedOptions = computed<EChartsOption>(() => {
    return {
      legend: {
        data: this.data().map(stationTimeSeries => stationTimeSeries.stationId)
      },
      series: this.data().map(stationTimeSeries => {
        stationTimeSeries.timeSeries.sort(compareTimeSeriesFunction);
        return {
          name: stationTimeSeries.stationId,
          type: 'line',
          data: stationTimeSeries.timeSeries.map(timeSeriesItem => {
            return {name: timeSeriesItem[0].toString() || '-', value: timeSeriesItem};
          })
        }
      })
    }
  });
  
  private getInitOptions(): EChartsOption {
    const actualTime = new Date().getTime();
    const minusXMinutes = new Date(actualTime - 15).getTime();
    const plusXMinutes = new Date(actualTime + 2).getTime();
    return {
      title: {
        text: 'WIND SPEED'
      },
      tooltip: {
        trigger: 'axis'
      },
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: 'none'
          },
          restore: {},
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'time',
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        boundaryGap: [0, '100%'],
        splitLine: {
          show: false,
        },
      },
      dataZoom: [
        {
          type: 'inside',
          start: plusXMinutes,
          end: minusXMinutes
        },
        {
          start: plusXMinutes,
          end: minusXMinutes
        }
      ],    
     };
  }

}
