import { ObjectValues } from './object-values.models';
import { SensorType, StationId } from './stations.models';

export const PERIOD = {
    PT24H: 'PT24H',
    PT1H: 'PT1H',
    PT10M: 'PT10M',
    PT5M: 'PT5M',
    PT2M: 'PT2M',
    PT1M: 'PT1M',
    PT30S: 'PT30S',
    PT15S: 'PT15S',
    PT10S: 'PT10S',
} as const;

export const UNIT_DURATION = {
    MINUTE: 'minute',
    SECOND: 'second',
    DAY: 'day',
} as const;

export type UnitDuration = ObjectValues<typeof UNIT_DURATION>;
export type Duration = ObjectValues<typeof PERIOD> | string;
export type DateISOString = string;

export type StationMeasurement = Omit<Measurement, 'stationId'>;

export type Measurement = {
    stationId: StationId,
    sensorType: SensorType;
    timestamp: DateISOString;
    frequency: Duration;
    samplingPeriod?: Duration;
    process: Process; 
    value:  number | string;
}

export type MeasuresByStationDto = {
    stationId: StationId,
    measures: Array<Measurement>,
}

export const UNIT = {
    KT: 'KNOT',
    CELSIUS: 'CELSIUS',
    H_PA: 'H_PA',
    PERCENT: 'PERCENT',
    MILIMETER: 'MILIMETER',
    CENTIMETER: 'CENTIMETER',
    METER: 'METER',
    M_BAR: 'M_BAR',
    DEGREE: 'DEGREE',
    CROSS_WIND_COMPONENT: 'CROSS_WIND_COMPONENT',
    WATTS_PER_METER2: 'WATTS_PER_METER2',
    HOUR: 'HOUR',
    CODE: 'CODE',
    UNKNOWN: 'UNKNOWN',
} as const;

export type Unit = ObjectValues<typeof UNIT>;

export const PROCESS = {
    AVERAGE: 'AVERAGE',
    MAXIMUM: 'MAXIMUM',
    MINIMUM: 'MINIMUM',
    TOTAL: 'TOTAL',
    CALCULATED: 'CALCULATED',
    SAMPLE: 'SAMPLE',
} as const;

export type Process = ObjectValues<typeof PROCESS>;

export type MeasurementConfig = {
    stationId: Array<StationId>,
    frequency: Array<Duration>,
    period: Array<Duration>,
    afterTimestamp: Date,
    sensorType: Array<SensorType>,
    process: Array<Process>,
};

export const MeasurementConfig = {
    ALL_FREQUENCIES: [],
    ALL_PERIODS: [],
    ALL_SENSOR_TYPES: [],
    ALL_PROCESS: [],
    ALL_STATIONS: [],
}

export const PARAMETER_STATUS = {
    ALARM: 'ALARM',
    NORMAL: 'NORMAL',
} as const;
export type ParameterStatus = ObjectValues<typeof PARAMETER_STATUS>;

export type Parameter = {
    id: number,
    sensorType: SensorType,
    process: Process,
    frequency: Duration,
    unit: Unit,
    status?: ParameterStatus,
}

export type SelectableParameter = Parameter & {id: number, selected: boolean};

export type ParameterCriteria = {
    sensorType: SensorType,
    process: Process,
    frequency: Duration,
}

// protected sensorCategories = ['Humidity', 'Temperature', 'Pressure', 'Precipitation', 'Radiation', 'Hydrological', 'Wind'];
// protected processes = ['Average', 'Min', 'Max', 'Sample'];
// protected measureParameters = [
//   {
//     name: 'Relative humidity',
//     period: PERIOD.PT1M
//   },
//   {
//     name: 'Relative humidity',
//     period: PERIOD.PT5M
//   },
//   {
//     name: 'Dew point',
//     period: PERIOD.PT1M
//   },
//   {
//     name: 'Dew point',
//     period: PERIOD.PT5M
//   },
// ]
