import { Injectable, inject } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { API_URL } from 'src/environments/url';
import { Process } from '../models/measurements.models';
import { SensorType, StationId, StationParameter } from '../models/stations.models';
import { Duration } from 'date-fns';
import { HttpClient } from '@angular/common/http';
import { getConfigItemParam } from '../utilities/http-utilities';
import { ObjectValues } from '../models/object-values.models';
import { SensorService } from './sensor.service';

export type ParameterFilter = {
  stationId: StationId[],
  sensorType: SensorType[],
  process: Process[],
  frequency: Duration[],
}

export const ParameterFiter = {
  ALL_FREQUENCIES: [],
  ALL_SENSOR_TYPES: [],
  ALL_PROCESS: [],
  ALL_STATIONS: [],
}

export const DEFAULT_PARAMETER_FILTER: ParameterFilter = {
  frequency: ParameterFiter.ALL_FREQUENCIES,
  sensorType: ParameterFiter.ALL_SENSOR_TYPES,
  stationId: ParameterFiter.ALL_STATIONS,
  process: ParameterFiter.ALL_PROCESS,
};

const PARAM_NAME = {
  STATION: 'stationId',
} as const;

@Injectable({
  providedIn: 'root'
})
export class ParametersService {
  
  http = inject(HttpClient);
  sensors = inject(SensorService);

  public get(parameterFilter: Partial<ParameterFilter> = DEFAULT_PARAMETER_FILTER): Observable<StationParameter> {
    const params = this.getRequestParams({
      ...DEFAULT_PARAMETER_FILTER,
      ...parameterFilter,
    });
    return this.http.get<StationParameter>(`${API_URL.PARAMETERS}?${params}`).pipe(
      tap(stationParameters => {
        Object.values(stationParameters).forEach(stationParameter => {
          stationParameter.forEach(parameter => {
            parameter.unit = this.sensors.getUnitBySensorCode(parameter.sensorType);
          })
        })
      })
    );
  }


  private getRequestParams(parameterFilter: ParameterFilter = DEFAULT_PARAMETER_FILTER): string {
    const lastParam = true;
    const stationIdParam = getConfigItemParam<ObjectValues<typeof PARAM_NAME>, StationId>(PARAM_NAME.STATION, parameterFilter.stationId, lastParam);
    return `${stationIdParam}`;
  }

}
