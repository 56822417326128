import { ObjectValues } from "./object-values.models"
import { StationId } from "./stations.models";

export const LANDING_ZONE = {
    TOUCHDOWN: 'TOUCHDOWN',
    MIDDLE: 'MIDDLE',
    ROLLOUT: 'ROLLOUT',
};

export type LandingZone = ObjectValues<typeof LANDING_ZONE>;

export type Heading = {
    name: string,
    value: number,
};

export type Runway = {
    id: string,
    name: string,
    heading: Heading,
    oppositeHeading?: Heading,
    stations: OrderedStationList,
};

export const TOUCHDOWN_INDEX = '1';
export const MIDDLE_INDEX = '2';
export const END_INDEX = '3';
export const MET_GARDEN_INDEX = '4';

export type OrderedStationList = {
    [key: string]: StationId;
};
