import { Component, computed, input } from '@angular/core';

import * as Highcharts from 'highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import More from 'highcharts/highcharts-more';
import { WindRoseData } from 'src/app/shared/models/wind-rose.models';

More(Highcharts);
const DIRECTION_CATEGORIES = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'];
@Component({
    selector: 'dipla-card-wind-rose',
    standalone: true,
    imports: [HighchartsChartModule],
    templateUrl: './card-wind-rose.component.html',
    styleUrls: ['./card-wind-rose.component.scss']
})
export class CardWindRoseComponent {

    data = input.required<WindRoseData>();
    position = input.required<string>();
    title = input<string>('');
    subtitle = input<string>('');

    protected highcharts: typeof Highcharts = Highcharts;
    protected chartOptions = computed<Highcharts.Options>(() => {
        const total = this.data().windRoseData.reduce((sum, series) => {
            return sum + series.data.reduce((seriesSum, value) => seriesSum + value, 0);
        }, 0);
        return {
            chart: {
                polar: true,
                type: 'column',
            },
            legend: {
                title: {
                    text: 'The last 24 hours in knots',
                },
            },
            title: {
                text: this.title(),
            },

            subtitle: {
                text: this.subtitle(),
            },

            pane: {
                size: '85%',
            },

            xAxis: {
                categories: DIRECTION_CATEGORIES,
                tickmarkPlacement: 'on',
            },
            yAxis: {
                min: 0,
                endOnTick: false,
                showLastLabel: false,
                labels: {
                    enabled: false,
                },
                reversedStacks: false,
            },        
            tooltip: {
                trigger: 'item',
                formatter: function () {
                    const value = Number(this.y);
                    const percentage = ((value / total) * 100).toFixed(2);
                    return `<span>${this.x}</span><br/><b>${this.series.name}</b>: ${percentage}%`;
                },
            },
            plotOptions: {
                series: {
                    stacking: 'normal',
                    shadow: false,
                    pointPlacement: 'on'
                },
                column: {
                    groupPadding: 0,
                }
            },
            credits: {
                enabled: false,
            },
            series: [
                {
                    name: this.data().windRoseData[0].name,
                    data: this.data().windRoseData[0].data,
                    type: 'column',
                    className: '',
                },
                {
                    name: this.data().windRoseData[1].name,
                    data: this.data().windRoseData[1].data,
                    type: 'column',
                    className: '',
                },
                {
                    name: this.data().windRoseData[2].name,
                    data: this.data().windRoseData[2].data,
                    type: 'column',
                    className: '',
                },
                {
                    name: this.data().windRoseData[3].name,
                    data: this.data().windRoseData[3].data,
                    type: 'column',
                    className: '',
                },
                {
                    name: this.data().windRoseData[4].name,
                    data: this.data().windRoseData[4].data,
                    type: 'column',
                    className: '',
                },
                {
                    name: this.data().windRoseData[5].name,
                    data: this.data().windRoseData[5].data,
                    type: 'column',
                    className: '',
                },
            ]
        }
    });

}
