import { ApplicationConfig, importProvidersFrom, APP_INITIALIZER, inject } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';

import { KeycloakAngularModule, KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { Observable, catchError, from, of, tap } from 'rxjs';

import { routes } from './app.routes';
import { environment } from 'src/environments/environment';
import { MeasurementRxStompService } from './shared/services/stomp/measurements-stomp.service';
import { rxStompServiceFactory } from './shared/services/stomp/rx-stomp-service-factory';
import { UserService } from './shared/services/user.service';
import { AlertRxStompService } from './shared/services/stomp/alert-stomp.service';
import { authorizationTokenInterceptor } from './shared/interceptors/interceptors';
import { ParameterRxStompService } from './shared/services/stomp/parameter-stomp.service';
import { STREAM_URL } from 'src/environments/url';
import { StationRxStompService } from './shared/services/stomp/station-stomp.service';


export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(KeycloakAngularModule),
    provideRouter(routes), 
    provideAnimations(),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: AlertRxStompService,
      useFactory: () => rxStompServiceFactory(AlertRxStompService, {
        brokerURL: STREAM_URL.ALERT_BROKER,
      }),
    },
    {
      provide: MeasurementRxStompService,
      useFactory: () => rxStompServiceFactory(MeasurementRxStompService, {
        brokerURL: STREAM_URL.AWOS_BROKER,
      }),
    },
    {
      provide: ParameterRxStompService,
      useFactory: () => rxStompServiceFactory(ParameterRxStompService, {
        brokerURL: STREAM_URL.PARAMETER_BROKER,
      }),
    },
    {
      provide: StationRxStompService,
      useFactory: () => rxStompServiceFactory(StationRxStompService, {
        brokerURL: STREAM_URL.STATION_BROKER,
      }),
    },
    provideHttpClient(withInterceptors([authorizationTokenInterceptor])),
  ]
};

function initializeKeycloak(keycloak: KeycloakService): () => Observable<boolean> {
  keycloak.keycloakEvents$.subscribe({
    next(event) {
      if (event.type == KeycloakEventType.OnTokenExpired) {
        keycloak.updateToken(20);
      }
    },
  });

  const userService = inject(UserService);
  const initConfig = from(keycloak.init({
    config: {
      url: `${environment.KEYCLOAK_URL}/`,
      realm: `${environment.KEYCLOAK_REALM}`,
      clientId: `${environment.KEYCLOAK_CLIENT_ID}`,

    },
    initOptions: {
      onLoad: 'login-required',
    },
    enableBearerInterceptor: true,
    shouldAddToken: () => true,
  })).pipe(
    tap(authenticated => userService.setAuthenticated(authenticated)),
    catchError(e => {
      console.error('auth initialization failed');
      if (environment.production === false) {
        console.error('development mode activated: no auth initialization');
        return of(true);
      } else throw e;
    }),
  );
  return () => initConfig;
}
