import { Component } from '@angular/core';
import { AlertSettingsTableComponent } from '../alert-settings-table/alert-settings-table.component';
import { AlertSettingsFilterComponent } from '../alert-settings-filter/alert-settings-filter.component';
import { CloseDialogDirective } from 'src/app/shared/directives/close-dialog.directive';

@Component({
  selector: 'dipla-alert-settings',
  standalone: true,
  imports: [
    AlertSettingsTableComponent,
    AlertSettingsFilterComponent,
  ],
  hostDirectives: [
    CloseDialogDirective,
  ],
  templateUrl: './alert-settings.component.html',
  styleUrl: './alert-settings.component.scss'
})
export class AlertSettingsComponent {
}
