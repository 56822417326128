import { Component, OnDestroy, computed, inject, input, signal, viewChild } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { toObservable } from '@angular/core/rxjs-interop';
import { AsyncPipe, CommonModule, LowerCasePipe, NgOptimizedImage, SlicePipe, UpperCasePipe } from '@angular/common';

import { Observable, Subject, filter, from, map, mergeAll, mergeMap, of, scan, takeUntil } from 'rxjs';

import { STATUS, Station } from '../shared/models/stations.models';
import { GraphsSeriesComponent } from '../shared/ui/graph-series/graph-series.component';
import { MeasurementConfig, Parameter, ParameterCriteria, SelectableParameter } from '../shared/models/measurements.models';
import { MeasurementsService } from '../shared/services/measurements.service';
import { SensorService } from '../shared/services/sensor.service';
import { TimeSeriesOptions } from '../shared/models/time-series.models';
import { parseMeasurementValueToNumberValue } from '../shared/utilities/measurement-utilities';
import { SensorPipe } from '../shared/pipes/sensor.pipe';
import { ParameterSelectionChange, ParameterSelectorComponent, SELECTOR_ACTION } from '../shared/ui/parameter-selector/parameter-selector.component';
import { LOCAL_STORAGE_ITEM, LocalStorageService } from '../shared/services/localStorage.service';
import { calculateAfterTimestamp } from '../shared/utilities/duration-utilities';
import { WindRoseChartComponent } from '../shared/ui/wind-rose-chart/wind-rose-chart.component';
import { handlingMissingImage } from '../shared/utilities/img-utilities';
import { transformParameter } from '../shared/utilities/parameter.utilities';
import { CodePipe } from '../shared/pipes/code.pipe';


@Component({
  selector: 'dipla-station',
  standalone: true,
  imports: [
    CommonModule, NgOptimizedImage,
    MatSidenavModule, MatButtonModule, MatListModule, MatExpansionModule, MatIconModule,
    GraphsSeriesComponent, ParameterSelectorComponent, WindRoseChartComponent,
    UpperCasePipe, LowerCasePipe, SlicePipe, SensorPipe, AsyncPipe, CodePipe,
  ],
  templateUrl: './station.component.html',
  styleUrls: ['./station.component.scss'],
})
export class StationComponent implements OnDestroy {
  STATUS = STATUS;

  // Inject services
  measurementService = inject(MeasurementsService);
  sensors = inject(SensorService);
  localStorageService = inject(LocalStorageService);

  // Input / Output
  station = input.required<Station>();
  selectedParameters = input<ParameterCriteria[]>(new Array<ParameterCriteria>());

  protected handlingMissingImage = handlingMissingImage;
  protected selectableParameters = computed<SelectableParameter[]>(() => {
    let selectedParameters = this.selectedParameters();
    if (!selectedParameters || (selectedParameters && selectedParameters.length === 0)) {
      let saveSelectedParameters = this.localStorageService.getStationItem<Parameter[]>(this.station().id, LOCAL_STORAGE_ITEM.SELECTED_PARAMETERS);
      if (!saveSelectedParameters) {
        saveSelectedParameters = this.localStorageService.getItem<Parameter[]>(LOCAL_STORAGE_ITEM.SELECTED_PARAMETERS) ?? [];
      }
      selectedParameters = [...saveSelectedParameters];
    }
    return this.station().parameters.map((parameter, index) => ({
      ...parameter,
      selected: selectedParameters?.some(savedParameter =>
        parameter.frequency === savedParameter.frequency &&
        parameter.process === savedParameter.process &&
        parameter.sensorType === savedParameter.sensorType
      ) || false,
      id: index,
    }));
  });

  private selectorComponent = viewChild.required(ParameterSelectorComponent);
  private selectionChange = toObservable(computed(() => this.selectorComponent()?.selectionChange()));

  protected opened = signal(true);
  protected sidenavIcon = computed(() => {
    return this.opened() ? 'fullscreen' : 'fullscreen_exit';
  });

  protected title = computed(() => {
    return 'Title';
  });

  onOpened(): void {
    this.opened.update(status => !status);
  }

  private unsubscribeTimeSeries = new Subject<number>();

  protected selectedTimeSeries$: Observable<TimeSeriesOptions[]> = this.selectionChange.pipe(
    filter((changes): changes is ParameterSelectionChange => !!changes),
    mergeMap(changes => {
      if (changes.action === SELECTOR_ACTION.SELECT) {
        return this.createTimeSeriesOptionsStream(changes.items);
      } else {
        return from(changes.items.map(item => {
          this.unsubscribeTimeSeries.next(item.id);
          return of({
            options: {
              id: item.id,
              name: this.getTimeSeriesName(item),
              active: false,
              parameter: item,
            },
            timeSeries: []
          })
        })).pipe(mergeAll());
      }
    }),
    scan((map, item: TimeSeriesOptions) => {
      const itemId = item.options.id;
      if (item.options.active) {
        const found = map.has(itemId);
        if (found) {
          const itemFound = map.get(itemId) as TimeSeriesOptions;
          item.timeSeries;
          itemFound.timeSeries = [...itemFound.timeSeries, ...item.timeSeries];
        } else {
          map.set(itemId, item);
        }
      } else {
        map.delete(itemId);          
      }
      return map;
    }, new Map<number, TimeSeriesOptions>()),
    map(map => Array.from(map.values())),
  );

  private createTimeSeriesOptionsStream(items: SelectableParameter[]): Observable<TimeSeriesOptions> {
    let result = new Array<Observable<TimeSeriesOptions>>();
    if (this.station()) {
      result = items.map(item => {
        const afterTimestamp = calculateAfterTimestamp(item.frequency);
        const config: MeasurementConfig = {
          stationId: [this.station().id],
          afterTimestamp,
          frequency: [item.frequency],
          period: MeasurementConfig.ALL_PERIODS,
          process: [item.process],
          sensorType: [item.sensorType],
        };
        return this.measurementService.getMeasurements(config).pipe(
          takeUntil(this.unsubscribeTimeSeries.pipe(filter(n => n === item.id))),
          map(measurements => ({
            options: {
              id: item.id,
              name: this.getTimeSeriesName(item),
              active: true,
              parameter: item,
            },
            timeSeries: measurements.map(measurement => [new Date(measurement.timestamp).getTime(), parseMeasurementValueToNumberValue(measurement.value)])
          })),
        );
      });
    } 
    return from(result).pipe(mergeAll());
  }

  private getTimeSeriesName(item: SelectableParameter): string {
    return transformParameter({
      sensor: this.sensors.sensors().find(sensor => sensor.type === item.sensorType),
      frequency: item.frequency,
      process: item.process,
    });
  }

  ngOnDestroy(): void {
    const selectedParameters = this.selectorComponent()?.selectedParameters();
    if (selectedParameters.length) {
      this.localStorageService.setItem(LOCAL_STORAGE_ITEM.SELECTED_PARAMETERS, selectedParameters);
      this.localStorageService.setStationItem(this.station().id, LOCAL_STORAGE_ITEM.SELECTED_PARAMETERS, selectedParameters);
    }
  }
}

