import { Component, inject } from '@angular/core';
import { AlertsFilterComponent } from './alerts-filter/alerts-filter.component';
import { AlertsTableComponent } from './alerts-table/alerts-table.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { AlertSettingsComponent } from './alert-settings/alert-settings.component';

@Component({
  selector: 'dipla-alerts',
  standalone: true,
  imports: [
    AlertsFilterComponent, AlertsTableComponent,
    MatButtonModule, MatDialogModule,
  ],
  templateUrl: './alerts.component.html',
  styleUrl: './alerts.component.scss',
})

export class AlertsComponent {

  dialog = inject(MatDialog);

  onAlertSettings() {
    this.dialog.open(AlertSettingsComponent, {
      height: '90%',
      width: '90%',
      maxWidth: '100%',
    }); 
  }
}
