import { ObjectValues } from "./object-values.models";

export const FEATURE_FLAG = {
    MULTIPLE_RUNWAYS: 'X_WAYS_FEATURE_FLAG',
    LOGO_CLIENT: 'LOGO_CLIENT_FEATURE_FLAG',
} as const;
export type FeatureFlagCode = ObjectValues<typeof FEATURE_FLAG>;

export type FeatureFlag = {
    code: FeatureFlagCode,
    enabled: boolean,
}