import { Component, inject, signal } from '@angular/core';

import { MapContentComponent } from './map-content/map-content.component';
import { StationsSummaryComponent } from './stations-summary/stations-summary.component';
import { Station } from '../shared/models/stations.models';
import { StationStore } from '../shared/services/stations-store';

@Component({
  selector: 'dipla-map',
  standalone: true,
  imports: [MapContentComponent, StationsSummaryComponent],
  template: `
    <div class="map-container">
      <dipla-map-content [stations]="stations() || []" (stationsInViewChanged)="this.stationsInView$.set($event)" class="left-map-container"/>
      <dipla-stations-summary [stations]="stationsInView$()" class="right-map-container"/>
    </div>
  `,
  styleUrls: ['./map.component.scss']
})
export class MapComponent {

  stationsService = inject(StationStore);

  protected stations = this.stationsService.stations;
  protected stationsInView$ = signal<Array<Station>>(new Array<Station>());
}
