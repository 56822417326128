import { Component, Signal, computed, inject, viewChild } from '@angular/core';
import { DatePipe, NgClass } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIcon } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';

import { ALERT_SETTING_STATUS, AlertSettingData } from 'src/app/shared/models/alerts.model';
import { customFilterPredicate } from 'src/app/shared/utilities/mat-table-utilities';
import { AlertSettingsStore } from 'src/app/shared/services/alert-settings-store';
import { InputComponent } from 'src/app/shared/ui/input/input.component';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CodePipe } from 'src/app/shared/pipes/code.pipe';
import { parseIntOrUndefined } from 'src/app/shared/utilities/number-utilities';

@Component({
  selector: 'dipla-alert-settings-table',
  standalone: true,
  imports: [
    DatePipe, CodePipe,
    NgClass,
    MatTableModule, MatSortModule, MatPaginatorModule, MatSlideToggleModule,
    MatIcon, MatTooltip, MatButtonModule, MatProgressSpinnerModule,
    InputComponent,
  ],
  templateUrl: './alert-settings-table.component.html',
  styleUrl: './alert-settings-table.component.scss'
})
export class AlertSettingsTableComponent {
  protected ALERT_SETTING_STATUS = ALERT_SETTING_STATUS;

  alertSettingStore = inject(AlertSettingsStore);

  alertSettingDataTable = this.alertSettingStore.alertSettingDataTable;
  loading = this.alertSettingStore.loading;
  filterValue = this.alertSettingStore.filterTerm;

  displayedColumns: string[] = ['stationName', 'parameter', 'unit', 'minThreshold', 'maxThreshold', 'status', 'emailNotification', 'pushNotification'];
  
  paginator = viewChild.required(MatPaginator);
  sort = viewChild.required(MatSort);
  dataSource:Signal<MatTableDataSource<AlertSettingData>> = computed(() => {
    const dataSource = new MatTableDataSource<AlertSettingData>(this.alertSettingDataTable());
    dataSource.paginator = this.paginator();
    dataSource.sort = this.sort();
    dataSource.filterPredicate = customFilterPredicate;
    dataSource.filter = this.filterValue().trim().toLowerCase();
    return dataSource;
  });

  onMinThresholdChange(alertSettingId: string, minThreshold: number | string | undefined) {
    const minThresholdNumber = parseIntOrUndefined(minThreshold);
    this.alertSettingStore.update({
      id: alertSettingId, 
      changes: {minThreshold: minThresholdNumber}
    });
  }

  onMaxThresholdChange(alertSettingId: string, maxThreshold: number | undefined) {
    const maxThresholdNumber = parseIntOrUndefined(maxThreshold);
    this.alertSettingStore.update({
      id: alertSettingId, 
      changes: {maxThreshold: maxThresholdNumber}
    });
  }

  onAlertSettingStatusChange(alertSettingId: string, toggleChange: MatSlideToggleChange) {
    this.alertSettingStore.update({
      id: alertSettingId, 
      changes: {status: toggleChange.checked ? ALERT_SETTING_STATUS.ACTIVE : ALERT_SETTING_STATUS.INACTIVE}
    });
  }

  onEmailNotificationChange(alertSettingId: string, event: Event) {
    this.alertSettingStore.update({
      id: alertSettingId, 
      changes: {emailNotification: (event.target as HTMLInputElement).checked}
    });
  }

  onPushNotificationChange(alertSettingId: string, event: Event) {
    this.alertSettingStore.update({
      id: alertSettingId, 
      changes: {pushNotification: (event.target as HTMLInputElement).checked}
    });
  }
}
