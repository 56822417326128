<div class="card shadow p-2 mb-3 shadow-sm">
  <div class="row card-container">
    <div class="col chart-content">
    <figure class="highcharts-figure">
        <div id="container" class="highcharts-dashboards-light">
            <highcharts-chart
            [Highcharts]="highcharts"
            [options]="chartOptions()"
          ></highcharts-chart>
        </div>
    </figure>
    </div>
  </div>
</div>
