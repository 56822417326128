import { inject } from '@angular/core';
import { patchState, signalStore, type, withHooks, withMethods } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { setAllEntities, withEntities } from '@ngrx/signals/entities';
import { pipe, switchMap, tap } from 'rxjs';

import { ConfigService } from './config.service';
import { FeatureFlag } from '../models/configs.model';

export const ConfigStore = signalStore(
  { providedIn: 'root' },
  withEntities(
    { entity: type<FeatureFlag>() },
  ),
  withMethods((store) => {
    const configService = inject(ConfigService);
    return {
      loadConfig: rxMethod<void>(pipe(
        switchMap(() => configService.getFeatureFlags()),
        tap((featureFlags: FeatureFlag[]) => {
          patchState(store, setAllEntities(featureFlags, {idKey: 'code'}));
        }),
      )),
    }
  }),
  withHooks({
    onInit(store) {
      store.loadConfig();
    },
  })
);

export type ConfigStore = InstanceType<typeof ConfigStore>;

