import { computed, inject } from '@angular/core';
import { SENSOR, SOURCE, Station, StationDTO, StationStatusDataStream } from '../models/stations.models';
import { map, pipe, switchMap, tap } from 'rxjs';
import { DateISOString, Measurement, MeasurementConfig, PERIOD, PROCESS, StationMeasurement } from '../models/measurements.models';
import { MeasurementsService, DEFAULT_MEASUREMENT_CONFIG } from './measurements.service';
import { ParametersService } from './parameters.service';
import { StationRxStompService } from './stomp/station-stomp.service';
import { MESSAGE_TYPE } from '../models/rx-stomp.models';
import { patchState, signalStore, type, withComputed, withHooks, withMethods } from '@ngrx/signals';
import { setAllEntities, updateEntity, withEntities } from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { compareStationPriority } from '../utilities/station.utilities';
import { StationsService } from './stations.service';

export const StationStore = signalStore(
  { providedIn: 'root' },
  withEntities(
    { entity: type<Station>() },
  ),
  withComputed(({ entities }) => ({
    stations: computed(() => entities().sort(compareStationPriority)),
    stationIdsAwos: computed(() => entities()
      .filter(station => station.source === SOURCE.AWOS)
      .map(station => station.id)
    )
  })

  ),
  withMethods((store) => {
    const stationService = inject(StationsService);
    const measurements = inject(MeasurementsService);
    const parameters = inject(ParametersService);
    const wsService = inject(StationRxStompService);
    return {
      loadStations: rxMethod<void>(pipe(
        switchMap(() => stationService.getStations()),
        switchMap(stations => {
          const stationIds = stations.map(station => station.id);
          const parametersFilter = { stationId: stationIds };
          return parameters.get(parametersFilter).pipe(
            map(stationParameters => {
              return stations.map(station => ({
                ...station,
                parameters: stationParameters[station.id] || [],
              }));
            })
          );
        }),
        tap(stations => {
          return stations.map(station => {return {
            ...station,
            measurements: new Array<Measurement>(),
          }});
        }),
        tap(stations => {
          patchState(store, setAllEntities(stations.sort(compareStationPriority)));
        }),
        switchMap(stations => {
          const afterTimestamp = new Date();
          afterTimestamp.setMinutes(afterTimestamp.getMinutes() - 5);
          const config: MeasurementConfig = {
            ...DEFAULT_MEASUREMENT_CONFIG,
            afterTimestamp,
            frequency: [PERIOD.PT1M],
            sensorType: [SENSOR.WIND_SPEED, SENSOR.WIND_DIRECTION, SENSOR.TEMPERATURE, SENSOR.PRECIPITATION],
            stationId: stations.map(station => station.id),
            process: [PROCESS.AVERAGE, PROCESS.SAMPLE],
          };
          return measurements.getLastMeasurements(config);
        }),
        tap((measurements: Measurement[]) => {
          store.entities().forEach(station => {
            const stationMeasurements = measurements.filter(measurement => measurement.stationId === station.id);
            patchState(store, updateEntity({
              id: station.id, changes: {
                measurements: stationMeasurements,
                lastUpdate: new Date(
                  stationMeasurements.reduce(
                    (lastUpdate: DateISOString, measurement: StationMeasurement) => measurement.timestamp > lastUpdate ? measurement.timestamp : lastUpdate,
                    ''
                  )
                )
              }
            }));
          })
        }),
      )),
      connectRealTimeStationStatus: rxMethod<void>(pipe(
        switchMap(() => wsService.connect<StationStatusDataStream>(MESSAGE_TYPE.STATION_STATUS)),
        map(data => data.stationStatus),
        tap(stationStatus => {
          Object.keys(stationStatus).forEach(stationId => {
            patchState(store, updateEntity({
              id: stationId, changes: {
                status: stationStatus[stationId],
              }
            }));
          });
        }),
      )),
    }
  }),
  withMethods(() => {
    const stationService = inject(StationsService);
    return {
      addStation: rxMethod<StationDTO>(pipe(
        tap(station => stationService.addStation(station))
      )),
      updateStation: rxMethod<StationDTO>(pipe(
        tap(station => stationService.updateStation(station))
      )),
    }
  }),
  withHooks({
    onInit(store) {
      store.loadStations();
      store.connectRealTimeStationStatus();
    },
  })
);

