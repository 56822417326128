import { Pipe, PipeTransform } from '@angular/core';
import { isThresholdAlert } from '../utilities/alerts-utilities';
import { Alert } from '../models/alerts.model';

@Pipe({
  name: 'isThresholdAlert',
  standalone: true,
  pure: true,
})
export class IsThresholdAlertPipe implements PipeTransform {

  transform(alert: Alert): boolean {
    return isThresholdAlert(alert);
  }

}
