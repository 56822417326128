import * as Leaflet from 'leaflet';


export const svgHtml = `
<svg width="23" height="34" viewBox="0 0 23 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M21.5073 7.00224C26.2659 16.9237 11.2186 34 11.2186 34C11.2186 34 -3.82859 16.9237 0.929989 7.00224C5.40792 -2.33408 17.0294 -2.33408 21.5073 7.00224ZM11.2186 15.3401C8.75289 15.3401 6.754 13.3412 6.754 10.8754C6.754 8.40966 8.75289 6.41077 11.2186 6.41077C13.6844 6.41077 15.6833 8.40966 15.6833 10.8754C15.6833 13.3412 13.6844 15.3401 11.2186 15.3401Z" fill="#2182C9"/>
</svg>
  `;

export const shadowUrl = 'assets/marker-shadow.svg';

export const divIconDefaultOptions: Leaflet.DivIconOptions = {
  html: svgHtml,
  shadowUrl,
  className: 'marker-nominal',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
};

export const iconDefault = Leaflet.divIcon({
  ...divIconDefaultOptions,
  className: 'marker-nominal'
});

export const svgHtmlAwosPOI = `
<svg width="25" height="41" viewBox="0 0 254 422" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M111.099 1.11786C58.483 7.21886 14.151 48.0059 2.53602 101C0.242022 111.466 -0.546979 133.578 0.944021 145.649C7.60202 199.561 38.138 273.924 90.814 364.5C104.156 387.442 125.898 422 126.989 422C128.062 422 144.443 396.052 159.187 371C195.664 309.016 224.626 248.405 239.475 202.969C254.226 157.836 257.517 124.502 250.087 95.4999C234.299 33.8809 175.12 -6.30614 111.099 1.11786ZM149.786 22.4149C168.791 26.5169 186.239 35.6789 200.185 48.8819C211.107 59.2229 217.615 67.7999 223.415 79.4999C249.125 131.368 228.934 194.536 178.073 221.352C161.364 230.163 145.951 234 127.276 234C82.291 234 42.141 205.403 26.682 162.35C21.122 146.864 19.33 123.905 22.421 107.75C31.065 62.5709 66.838 28.1359 112.5 21.0409C120.724 19.7629 140.95 20.5089 149.786 22.4149ZM93.862 59.3759C92.563 60.0199 90.645 62.1329 89.601 64.0709C86.312 70.1709 87.623 73.0549 103.092 93.7629L117.088 112.5L91.94 112.767L66.792 113.035L60.45 107.102C48.392 95.8229 46.633 97.1159 46.175 117.594C45.659 140.673 44.469 140 85.801 140C102.227 140 115.94 140.273 116.274 140.607C116.608 140.941 110.797 149.301 103.362 159.184C95.926 169.067 89.38 178.481 88.815 180.102C86.818 185.83 89.812 192.397 95.306 194.341C96.299 194.693 98.549 194.365 100.306 193.613C102.063 192.861 115.38 180.77 129.901 166.744L156.302 141.242L176.901 140.729C188.23 140.446 199.035 139.728 200.91 139.132C206.342 137.405 209 134.287 209 129.642C209 126.157 208.366 125.021 204.156 120.963C196.999 114.065 192.905 113 173.543 113H157.442L134.471 90.8589C121.837 78.6809 109.454 66.7509 106.954 64.3479C101.691 59.2879 97.357 57.6419 93.862 59.3759Z" fill="#3193D9"/>
</svg>
`;
