export function getConfigItemParam<P, T extends { toString(): string }>(paramName: P, configItem: Array<T> | T, lastParam = false): string {
    let result = '';
    if (Array.isArray(configItem)) {
        if (configItem.length === 0) {
            return result;
        } else {
            result = configItem.reduce((accumulator, currentConfigItem, index) => {
                return accumulator + `${currentConfigItem}${index !== configItem.length - 1 ? ',' : ''}`
            }, `${paramName}=`);
        }
    } else {
        result = `${paramName}=${configItem.toString()}`;
    }
    return `${result}${lastParam ? '' : '&'}`;
}
