import { ObjectValues } from "./object-values.models";

export const MESSAGE_TYPE = {
    MEASUREMENT: 'MEASURE',
    WINDROSE: 'WINDROSE',
    THRESHOLD: 'THRESHOLD',
    PARAMTER_STATUS: 'PARAMETER_STATUS',
    STATION_STATUS: 'STATION_STATUS',
} as const;
export type MessageType = ObjectValues<typeof MESSAGE_TYPE>;

