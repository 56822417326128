export const customFilterPredicate = (data: Record<string, unknown>, filter: string) => {
    const dataStr = Object.keys(data as unknown as Record<string, unknown>)
        .reduce((currentTerm: string, key: string) => {
            // Use an obscure Unicode character to delimit the words in the concatenated string.
            // This avoids matches where the values of two columns combined will match the user's query
            // (e.g. `Flute` and `Stop` will match `Test`). The character is intended to be something
            // that has a very low chance of being typed in by somebody in a text field. This one in
            // particular is "White up-pointing triangle with dot" from
            // https://en.wikipedia.org/wiki/List_of_Unicode_characters
            return currentTerm + (data as unknown as Record<string, unknown>)[key] + '◬';
        }, '')
        .toLowerCase();

    // Transform the filter by converting it to lowercase and removing whitespace.
    const filters = filter.split(' ');

    return filters.every(filter => dataStr.indexOf(filter.toLowerCase()) != -1);
}
