import { Injectable, computed, inject } from "@angular/core";
import { UserService } from "./user.service";
import { ObjectValues } from "../models/object-values.models";
import { StationId } from "../models/stations.models";
import { hashString } from "../utilities/hash-utilities";

export const LOCAL_STORAGE_ITEM = {
    SELECTED_PARAMETERS: 'SELECTED_PARAMETERS',
} as const;

export type Item = ObjectValues<typeof LOCAL_STORAGE_ITEM>;

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {

    private userService = inject(UserService);
    private userHash = computed(() => hashString(this.userService.user()?.username || ''));

    public getItem<T>(item: Item): T | null {
        return this._getItem<T>(item);
    }
    
    public setItem<T>(item: Item, value: T): void {
        this._setItem<T>(item, value);
    }

    public getStationItem<T>(stationId: StationId, item: Item): T | null {
        return this._getItem<T>(`${item}-${stationId}`);
    }

    public setStationItem<T>(stationId: StationId, item: Item, value: T): void {
        this._setItem<T>(`${item}-${stationId}`, value);
    }

    private _getItem<T>(item: string): T | null {
        const itemValue = localStorage.getItem(`${this.userHash()}-${item}`);
        return itemValue ? JSON.parse(itemValue) as T: null;
    }

    private _setItem<T>(item: string, value: T): void {
        localStorage.setItem(`${this.userHash()}-${item}`, JSON.stringify(value));  
    }
}