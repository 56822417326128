import { Component, HostBinding, Input, inject, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { ThemePalette } from '@angular/material/core';

import { UserProfile } from '../../models/user.model';
import { UserFullnamePipe } from '../../pipes/user-fullname.pipe';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'dipla-user-authentication',
  standalone: true,
  imports: [
    MatIconModule, MatButtonModule, MatMenuModule,
    UserFullnamePipe,
  ],
  template: `
    <span class="user">{{user() | fullname}}</span>

    <div class="expand_more">
      <button mat-icon-button [color]="color" [matMenuTriggerFor]="menu">
        <mat-icon aria-hidden="false" aria-label="">expand_more</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onLogout()">
          <mat-icon>logout</mat-icon>
          <span>Log out</span>
        </button>
      </mat-menu>
    </div>
  `,
  styles: [
    `
    :host {
      display: flex;
      align-items:center;
      justify-content: flex-end;
    }
    .expand_more {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
  }
  
    `
  ]
})
export class UserAuthenticationComponent {

  authenticateService = inject(KeycloakService);

  @Input() color: ThemePalette | 'none' = 'primary';
  user = input<UserProfile>();

  @HostBinding('class')
  get hostClass() {
    if (this.color === 'none')
      return;
    return `${this.color}-user-authentication`;
  }

  onLogout(): void {
    this.authenticateService.logout(environment.HOME_URL);
  }
}
