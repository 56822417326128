import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { SelectableParameter } from '../models/measurements.models';
import { List } from 'immutable';
import { computed, inject } from '@angular/core';
import { SensorService } from './sensor.service';
import { pipe, tap } from 'rxjs';
import { ParameterSelectionChange, SELECTOR_ACTION } from '../ui/parameter-selector/parameter-selector.component';
import { SENSOR } from '../models/stations.models';

const initialValue: {
    parameters: Array<SelectableParameter>,
    lastAction: null | ParameterSelectionChange,
    parameterSearchTerm: string,
} = {
    parameters: new Array<SelectableParameter>(),
    lastAction: null,
    parameterSearchTerm: '',
}

export const ParameterSelectorStore = signalStore(
    withState(initialValue),
    withComputed((store) => {
        const filterBySearchTerm = (searchTerm: string, selectableParameter: SelectableParameter) => {
            const searchTermsParsed = searchTerm.trim().toUpperCase().split(' ');
            if (searchTermsParsed.length === 0) return true;
            return searchTermsParsed.every(term => 
                selectableParameter.frequency.toUpperCase().includes(term) ||
                selectableParameter.process.toUpperCase().includes(term) ||
                selectableParameter.sensorType.toUpperCase().includes(term)
            );
        };
        return {
            selectedParameters: computed(() => 
                store.parameters()
                .filter(parameter => parameter.selected)
            ),
            filteredParameters: computed(() => 
                store.parameters()
                .filter(parameter => !parameter.selected && filterBySearchTerm(store.parameterSearchTerm(), parameter))
            ),
        }
    }),
    withMethods((store) => ({
        init: rxMethod<SelectableParameter[]>(pipe(
            tap((parameters) => {
                let lastAction: ParameterSelectionChange;
                const addedParameters = parameters?.filter(parameter => parameter.selected);
                if (addedParameters.length) {
                    lastAction = {
                        action: SELECTOR_ACTION.SELECT,
                        items: addedParameters
                    }
                }
                patchState(store, () => ({
                    parameters,
                    lastAction,
                }));
            })
        )),
        filterSearchTerm(searchTerm: string): void {
            patchState(store, () => ({
               parameterSearchTerm: searchTerm, 
            }))
        },
        toggleParameter(idToUpdate: number): void {
            patchState(store, (state) => {
                const parameters = [...state.parameters];
                const parameterToUpdate = parameters[idToUpdate];
                parameterToUpdate.selected = !parameterToUpdate.selected;
                const updateItems = [parameterToUpdate];
                if (parameterToUpdate.sensorType === SENSOR.WIND_DIRECTION && parameterToUpdate.selected) {
                    const windSpeedParameters = parameters.find(parameter => parameter.frequency === parameterToUpdate.frequency &&
                        parameter.process === parameterToUpdate.process && parameter.sensorType === SENSOR.WIND_SPEED);
                    if (windSpeedParameters) {
                        windSpeedParameters.selected = true;
                        updateItems.push(windSpeedParameters);
                    }
                } else if (parameters[idToUpdate].sensorType === SENSOR.WIND_SPEED && !parameters[idToUpdate].selected) {
                    const windDirectionParameters = parameters.find(parameter => parameter.frequency === parameterToUpdate.frequency &&
                        parameter.process === parameterToUpdate.process && parameter.sensorType === SENSOR.WIND_DIRECTION);
                    if (windDirectionParameters) {
                        windDirectionParameters.selected = false;
                        updateItems.push(windDirectionParameters);
                    }
                }
                return {
                    parameters,
                    lastAction: {
                        action: parameters[idToUpdate].selected ? SELECTOR_ACTION.SELECT : SELECTOR_ACTION.DESELECT,
                        items: updateItems
                    },
                };
            })
        },
    })),
    withComputed((store, sensors = inject(SensorService)) => ({
        groupByCategoryThenProcess: computed(() => {
            return List(store.filteredParameters())
                .groupBy(parameter => sensors.getCategoryCode(parameter.sensorType))
                .map(sensorGroup => sensorGroup.groupBy(item => item.process))          
        }),
        sensors: computed(() => sensors.sensors()),
        categories: computed(() => sensors.categories()),
    })),
);