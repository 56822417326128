import { Injectable, inject } from '@angular/core';
import { Marker, Map as LeafletMap, marker as leafletMarker, divIcon as leafletDivIcon, tooltip } from 'leaflet';
import { SOURCE, STATUS, Station } from '../models/stations.models';
import { svgHtmlAwosPOI, divIconDefaultOptions, iconDefault } from '../constants/marker.constants';
import { MatDialog } from '@angular/material/dialog';
import { DialogStationComponent } from 'src/app/map/dialog-station/dialog-station.component';
import { tap } from 'rxjs';
import { codeToCapitalize } from '../utilities/string-utilities';

Marker.prototype.options.icon = iconDefault;

@Injectable({
  providedIn: 'root'
})
export class MarkerService {

  private dialog = inject(MatDialog);

  makeStationMarkers(map: LeafletMap, stations: Station[]): void {
    for (const station of stations) {
      const divIcon = station.source !== SOURCE.AWOS ? {...divIconDefaultOptions} : ({
        ...divIconDefaultOptions,
        html: svgHtmlAwosPOI,
      });
      const lon = station.longitude;
      const lat = station.latitude;
      let marker: Marker;
      const content = `<h1>${station.name}</h1><br /><h2>Status: ${codeToCapitalize(station.status)}</h2>`;
      const tooltipClassNames = ['marker-tooltip'];
      switch (station.status) {
        case STATUS.DEGRADED: {
          marker = leafletMarker([lat, lon], {
            icon: leafletDivIcon({
              ...divIcon,
              className: 'marker-degraded',
            })});
            tooltipClassNames.push('tooltip-degraded');
          break;
        }
        case STATUS.MALFUNCTIONING: {
          marker = leafletMarker([lat, lon], {
            icon: leafletDivIcon({
              ...divIcon,
              className: 'marker-malfunctioning',
            })});
            tooltipClassNames.push('tooltip-malfunctioning');
          break;
        }
        case STATUS.NORMAL:
        default:
          marker = leafletMarker([lat, lon], {
            icon: leafletDivIcon({...divIcon})
          });
          tooltipClassNames.push('tooltip-nominal');
        }
      marker.bindTooltip(tooltip({className: tooltipClassNames.join(' '), content})).openTooltip();
      marker.on('click', (event) => {
        this.onMarkerClick(event, station);
      });
      marker.addTo(map);
    }
  }

  private onMarkerClick(event: L.LeafletMouseEvent, station: Station): void {
    const stationDialogRef = this.dialog.open(DialogStationComponent, {
      height: '90%',
      width: '90%',
      maxWidth: '100%',
      data: {station}
    });
    stationDialogRef.afterClosed().pipe(
      tap(() => stationDialogRef.close()),
    ).subscribe();
  }
}