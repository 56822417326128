@if (station(); as station) {
<mat-sidenav-container class="wrapper">
    <mat-sidenav mode="side" [(opened)]="opened" class="sidenav">
        <div class="mat-expansion-panel-header station-title">
            <img [src]="'../assets/img/station_icon_' + (station.source | lowercase ) + '_white.svg'" alt="">
            <span>{{station.name | uppercase}}</span>
        </div>
        <div class="station-header">
            <img class="mat-elevation-z2 header-img" mat-card-md-image [ngSrc]="'../assets/'+station.media"
                [alt]="station.name" (error)="handlingMissingImage($event)" width="150" height="150" loading="lazy" />
            <div class="header-details">
                <h4 class="status" [ngClass]="{
                        'degraded': station.status === STATUS.DEGRADED,
                        'malfunctioning': station.status === STATUS.MALFUNCTIONING,
                        'nominal': station.status === STATUS.NORMAL
                      }">
                    {{ station.status | code }}
                </h4>
                @if (station.lastUpdate.getTime() === station.lastUpdate.getTime()) {
                <div class="last-update">
                    <h6>{{ station.lastUpdate | date:'longDate' }}</h6>
                    <h6>{{ station.lastUpdate | date:'longTime' }}</h6>
                </div>
                }
            </div>
        </div>
        <dipla-parameter-selector [parametersStation]="selectableParameters()" />
    </mat-sidenav>
    <mat-sidenav-content class="main">
        <div class="toggle-nav">
            <button mat-icon-button aria-label="expand sidenav" (click)="onOpened()">
                <mat-icon>{{sidenavIcon()}}</mat-icon>
            </button>
        </div>
        @if (selectedTimeSeries$ | async; as timeSeriesOption) {
        <dipla-graphs-series #graph class="graph" [timeSeriesOptions]="timeSeriesOption" [title]="title()" />
        } @else {
        <div class="graph-empty-content">
            @if (selectableParameters().length) {
            <span>Please select parameter</span>
            } @else {
            <span>No data for this station</span>
            }
        </div>
        }
    </mat-sidenav-content>
</mat-sidenav-container>
}