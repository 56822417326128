import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentType } from '@angular/cdk/portal';
import { DEFAULT_SOUND_NOTIFICATION } from '../constants/notification.constants';

const DURATION = 8000;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private snackBar = inject(MatSnackBar);

  constructor(){
    Notification.requestPermission();
  }

  openNotification<T, D extends {label: string, message: string, status?: 'success' | 'alert'}>(component: ComponentType<T>, data: D) {
    if (document.visibilityState === 'visible') {
      this.snackBar.openFromComponent(component, {
        data,
        duration: DURATION,
        horizontalPosition: 'end',
        verticalPosition: 'top',
        panelClass: data.status ?? 'alert'
      });
    } else {
      if (Notification.permission === 'granted') {
        new Notification(`DIPla`, { body: `${data.label}\n${data.message}`, icon: 'assets/img/dipla-logo.svg' });
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            new Notification('DIPla', { body: `${data.label}\n${data.message}`, icon: 'assets/img/dipla-logo.svg' });
          } 
        });
      }
    }
    this.playSound();
  }

  playSound(audioUrl = DEFAULT_SOUND_NOTIFICATION) {
    const audio = new Audio(audioUrl);
    audio.play();    
  }

}
