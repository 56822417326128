import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { CloseDialogDirective } from 'src/app/shared/directives/close-dialog.directive';
import { ParameterCriteria } from 'src/app/shared/models/measurements.models';
import { Station } from 'src/app/shared/models/stations.models';
import { StationComponent } from 'src/app/station/station.component';

export type DialogStationData = {
  station: Station,
  parameters: ParameterCriteria[];
}

@Component({
  selector: 'dipla-dialog-station',
  standalone: true,
  imports: [
    MatDialogModule, MatIconModule,
    StationComponent,
  ],
  hostDirectives: [
    CloseDialogDirective,
  ],
  template: `
    <div class="wrapper">
      <dipla-station [station]="data.station" [selectedParameters]="data.parameters"/>
    </div>
  `,
  styles: `
  .wrapper {
    height: 100%;
  }
`,
})
export class DialogStationComponent {
  data: DialogStationData = inject(MAT_DIALOG_DATA);
}
