import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Signal, computed, inject, signal, viewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { StationStore } from '../shared/services/stations-store';
import { MODE, Station } from '../shared/models/stations.models';
import { MatDialog } from '@angular/material/dialog';
import { StationDialogFormComponent } from './station-dialog-form/station-dialog-form.component';
import { tap } from 'rxjs';
import { FilterComponent } from '../shared/ui/filter/filter.component';

@Component({
  selector: 'dipla-stations',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FilterComponent,
    DatePipe,
    MatTableModule, MatSortModule, MatPaginatorModule,
    MatIcon, MatTooltip, MatButtonModule, MatProgressSpinnerModule,
  ],
  templateUrl: './stations.component.html',
  styleUrl: './stations.component.scss'
})
export class StationsComponent {

  stationStore = inject(StationStore);
  dialog = inject(MatDialog);

  stations = this.stationStore.stations;
  filterTerm = signal<string>('');
  filteredStations = computed(() => this.stations()
    .filter(station => {
      const filterTerm = this.filterTerm();
      if (!filterTerm || filterTerm === '') {
        return true;
      } else {
        const filterTermIgnoreCase = filterTerm.toLowerCase();
        return station.name.toLowerCase().includes(filterTermIgnoreCase) ||
          station.id.toLowerCase().includes(filterTermIgnoreCase) ||
          station.source.toLowerCase().includes(filterTermIgnoreCase);
      }
    })
  );

  displayedColumns: string[] = ['id', 'name', 'latitude', 'longitude', 'source'];
  
  paginator = viewChild.required(MatPaginator);
  sort = viewChild.required(MatSort);
  dataSource:Signal<MatTableDataSource<Station>> = computed(() => {
    const dataSource = new MatTableDataSource<Station>(this.filteredStations());
    dataSource.paginator = this.paginator();
    dataSource.sort = this.sort();
    return dataSource;
  })

  onStationClick(station: Station){
    const stationDialogFormRef = this.dialog.open(StationDialogFormComponent, {
      height: '50%',
      minWidth: '30%',
      disableClose: true,
      data: {
        station: station,
        mode: MODE.UPDATE,
      }
    });
    stationDialogFormRef.afterClosed().pipe(
      tap(() => stationDialogFormRef.close()),
    ).subscribe();
  }
  
  onAddStations() {
    const stationDialogFormRef = this.dialog.open(StationDialogFormComponent, {
      height: '50%',
      minWidth: '30%',
      disableClose: true,
      data: {
        mode: MODE.CREATE,
      }
    });
    stationDialogFormRef.afterClosed().pipe(
      tap(() => stationDialogFormRef.close()),
    ).subscribe();
  }
}
