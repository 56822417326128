import { Component, computed, inject } from '@angular/core';
import { ConfigStore } from '../shared/services/config-store';
import { AwosMonoRunwayComponent } from './awos-mono-runway.component';
import { AwosMultiRunwaysComponent } from './awos-multi-runways.component';
import { FEATURE_FLAG } from '../shared/models/configs.model';

@Component({
  selector: 'dipla-awos',
  standalone: true,
  imports: [
    AwosMonoRunwayComponent, AwosMultiRunwaysComponent
  ],
  template: `
    @if (multiRunwaysEnabled()) {
      <dipla-awos-multi-ruwnays />
    } @else {
      <dipla-awos-mono-runway />
    }
  `,
})
export class AwosComponent {

  configStore = inject(ConfigStore);
  multiRunwaysEnabled = computed(() => 
    this.configStore.entities().find(feature => feature.code === FEATURE_FLAG.MULTIPLE_RUNWAYS)?.enabled
  )
}
