<div class=".alert-settings-table-container mat-elevation-z8">
    <table mat-table [dataSource]="dataSource()" matSort matSortActive="id">

        <!-- Message Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell *matCellDef="let alertSetting"> {{alertSetting.id}} </td>
        </ng-container>

        <!-- Station Column -->
        <ng-container matColumnDef="stationName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Station </th>
            <td mat-cell *matCellDef="let alertSetting"> {{alertSetting.stationName}} </td>
        </ng-container>

        <!-- Parameter Column -->
        <ng-container matColumnDef="parameter">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Parameter </th>
            <td mat-cell *matCellDef="let alertSetting"> {{alertSetting.parameter}} </td>
        </ng-container>

        <!-- Unit Column -->
        <ng-container matColumnDef="unit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit </th>
            <td mat-cell *matCellDef="let alertSetting"> {{alertSetting.unit | code}} </td>
        </ng-container>

        <!-- Minimum Threshold Column -->
        <ng-container matColumnDef="minThreshold">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Value Min </th>
            <td mat-cell *matCellDef="let alertSetting">
                <dipla-input type="number" [value]="alertSetting.minThreshold"
                    (valueChange)="onMinThresholdChange(alertSetting.id, $event)" />
            </td>
        </ng-container>

        <!-- Maximum Threshold Column -->
        <ng-container matColumnDef="maxThreshold">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Value Max </th>
            <td mat-cell *matCellDef="let alertSetting">
                <dipla-input type="number" [value]="alertSetting.maxThreshold"
                    (valueChange)="onMaxThresholdChange(alertSetting.id, $event)" />
            </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
            <td mat-cell *matCellDef="let alertSetting">
                <mat-slide-toggle color="primary" [checked]="alertSetting.status === ALERT_SETTING_STATUS.ACTIVE"
                    [disabled]="alertSetting.status === ALERT_SETTING_STATUS.NOT_CREATED || (!alertSetting.maxThreshold! && !alertSetting.minThreshold!)"
                    (change)="onAlertSettingStatusChange(alertSetting.id, $event)">
                    {{alertSetting.status | code}}
                </mat-slide-toggle>
            </td>
        </ng-container>

        <!-- Email notification Column -->
        <ng-container matColumnDef="emailNotification">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
            <td mat-cell *matCellDef="let alertSetting">
                <input type="checkbox" [disabled]="alertSetting.status === ALERT_SETTING_STATUS.NOT_CREATED"
                    [checked]="alertSetting.emailNotification"
                    (change)="onEmailNotificationChange(alertSetting.id, $event)" />
            </td>
        </ng-container>

        <!-- Push notification Column -->
        <ng-container matColumnDef="pushNotification">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Push Notif </th>
            <td mat-cell *matCellDef="let alertSetting">
                <input type="checkbox" [disabled]="alertSetting.status === ALERT_SETTING_STATUS.NOT_CREATED"
                    [checked]="alertSetting.pushNotification"
                    (change)="onPushNotificationChange(alertSetting.id, $event)" />
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let alertSetting; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        @if (!loading()) {
        <tr class="mat-row" *matNoDataRow class="no-data-row">
            <td class="mat-cell" colspan="7">No data matching the filter</td>
        </tr>
        }
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" aria-label="Select page of alerts">
    </mat-paginator>

    @if (loading()) {
    <div class="spinner-wrapper">
        <mat-spinner></mat-spinner>
    </div>
    }
</div>
