import { Pipe, PipeTransform } from '@angular/core';
import { Sensor, SensorType } from '../models/stations.models';
import { Duration, Process } from '../models/measurements.models';

type Parameter = {
  sensorType: SensorType,
  frequency: Duration,
  process: Process,
}

@Pipe({
  name: 'parameter',
  standalone: true
})
export class ParameterPipe implements PipeTransform {

  transform(parameter: Parameter, sensors: Sensor[]): string {
    const sensorLabel = sensors.find(sensor => sensor.type === parameter.sensorType)?.label || '';
    const frequencyLabel = parameter.frequency.toUpperCase();
    const processLabel = parameter.process.toLocaleLowerCase();
    return `${sensorLabel} ${processLabel} ${frequencyLabel}`;
  }

}
