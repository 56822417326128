import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { DIALOG } from 'src/app/shared/constants/dialog.constants';
import { CloseDialogDirective } from 'src/app/shared/directives/close-dialog.directive';
import { ALL_SOURCES, MODE, SOURCE, STATUS, Source, StationDTO, StationFormDataDialog, StationId, Status } from 'src/app/shared/models/stations.models';
import { patchState, signalState } from '@ngrx/signals';
import { FormsModule, NgForm } from '@angular/forms';
import { JsonPipe } from '@angular/common';
import { StationStore } from 'src/app/shared/services/stations-store';


export type StationFormState = {
  id: StationId | null;
  name: string | null;
  latitude: number | null;
  longitude: number | null;
  source: Source | null;
  status: Status,
}

type PartialStationState = Partial<StationFormState>;

const initialState: StationFormState = {
  id: null,
  name: null,
  latitude: null,
  longitude: null,
  source: null,
  status: STATUS.NORMAL,
};

@Component({
  selector: 'dipla-station-dialog-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule, JsonPipe,
    MatDialogModule, MatIconModule, MatButtonModule, MatFormFieldModule,
  ],
  hostDirectives: [
    CloseDialogDirective,
  ],
  templateUrl: './station-dialog-form.component.html',
  styleUrl: './station-dialog-form.component.scss'
})
export class StationDialogFormComponent {
  SOURCE = SOURCE;
  ALL_SOURCES = ALL_SOURCES;
  MODE = MODE;
  data: StationFormDataDialog = inject(MAT_DIALOG_DATA);

  #dialogRef = inject(MatDialogRef);
  #stationsService = inject(StationStore);
  globalError = signal<string | null>(null);
  stationState = signalState<StationFormState>({
    ...initialState,
    ...this.data.station,
  });

  patchState(partialState: PartialStationState) {
    patchState(this.stationState, partialState);
  }

  save(form: NgForm) {
    if (form.form.valid) {
      this.globalError.set(null);
      const station = {
        id: this.stationState.id(),
        name: this.stationState.name(),
        longitude: this.stationState.longitude(),
        latitude: this.stationState.latitude(),
        source: this.stationState.source(),
      } as StationDTO;
      if (this.data.mode === MODE.CREATE) {
        this.#stationsService.addStation(station);
      } else if (this.data.mode === MODE.UPDATE) {
        this.#stationsService.updateStation(station);
      }
      this.#dialogRef.close(DIALOG.SAVE);
    } else {
      this.globalError.set('Error');
    }
  }

  cancel() {
    this.#dialogRef.close(DIALOG.EXIT);
  }
}
