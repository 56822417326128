import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_SNACK_BAR_DATA, MatSnackBarAction, MatSnackBarActions, MatSnackBarLabel, MatSnackBarRef } from '@angular/material/snack-bar';
import { AlertStore } from '../../services/alerts-store';
import { ALERT_STATUS, Alert } from '../../models/alerts.model';
import { MatIcon } from '@angular/material/icon';

type AlertSnackBarData = {
  label: string,
  message: string,
  alert: Alert,
}
@Component({
  selector: 'dipla-alert-snack-bar',
  standalone: true,
  imports: [MatButtonModule, MatSnackBarLabel, MatSnackBarActions, MatSnackBarAction, MatIcon],
  templateUrl: './alert-snack-bar.component.html',
  styleUrl: './alert-snack-bar.component.scss'
})
export class AlertSnackBarComponent {
  ALERT_STATUS = ALERT_STATUS;

  data: AlertSnackBarData = inject(MAT_SNACK_BAR_DATA);
  store = inject(AlertStore);
  snackBarRef = inject(MatSnackBarRef);

  onDismissAction() {
    this.snackBarRef.dismissWithAction();
    this.store.acknowledgeAlert(this.data.alert.id);
  }
}
