import { Component, computed, inject, input, signal } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { STATUS, Station, StationId, StationParameter } from 'src/app/shared/models/stations.models';
import { UnitMeasurePipe } from 'src/app/shared/pipes/unit-measure.pipe';
import { ValueMeasurePipe } from 'src/app/shared/pipes/value-measure.pipe';
import { ValueUnitMeasurePipe } from 'src/app/shared/pipes/value-unit-measure.pipe';
import { MatDividerModule } from '@angular/material/divider';
import { SensorIconPipe } from 'src/app/shared/pipes/sensor-icon.pipe';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SensorService } from 'src/app/shared/services/sensor.service';
import { SensorPipe } from 'src/app/shared/pipes/sensor.pipe';
import { map, take, tap } from 'rxjs';
import { DialogStationComponent } from '../dialog-station/dialog-station.component';
import { handlingMissingImage } from 'src/app/shared/utilities/img-utilities';
import { CodePipe } from 'src/app/shared/pipes/code.pipe';
import { AlertStore } from 'src/app/shared/services/alerts-store';
import { Router } from '@angular/router';
import { BadgeComponent } from 'src/app/shared/ui/badge/badge.component';
import { PARAMETER_STATUS, Parameter } from 'src/app/shared/models/measurements.models';
import { ParameterFilter, ParametersService } from 'src/app/shared/services/parameters.service';
import { ParameterPipe } from 'src/app/shared/pipes/parameter.pipe';

@Component({
  selector: 'dipla-stations-summary',
  standalone: true,
  imports: [
    CommonModule, NgOptimizedImage, NgbTooltipModule,
    MatIconModule, MatDividerModule, MatCardModule, MatButtonModule, MatDialogModule,
    BadgeComponent,
    UnitMeasurePipe, ValueMeasurePipe, ValueUnitMeasurePipe, SensorIconPipe, NgbTooltipModule, SensorPipe, ParameterPipe, CodePipe,
  ],
  templateUrl: './stations-summary.component.html',
  styleUrls: ['./stations-summary.component.scss'],
})
export class StationsSummaryComponent {
  STATUS = STATUS;

  sensorsService = inject(SensorService);
  dialog = inject(MatDialog);
  alertsStore = inject(AlertStore);
  router = inject(Router);
  parameterService = inject(ParametersService);

  _stations = input.required<Array<Station>>({ alias: 'stations' });
  stations = computed<Array<Station & { unknowledgedAlertCount: number }>>(() => {
    return this._stations().map(station => {
      return {
        ...station,
        unknowledgedAlertCount: this.alertsStore.getUnacknowledgedAlertsCountByStation(station.id),
      }
    })
  })
  protected sensors = this.sensorsService.sensors;
  protected tooltipLoading = signal(true);
  protected degradedParametersTooltip = signal(new Array<Parameter>());

  protected onStationCardClick(station: Station) {
    const stationDialogRef = this.dialog.open(DialogStationComponent, {
      height: '90%',
      width: '90%',
      maxWidth: '100%',
      data: { station },
    });
    stationDialogRef.afterClosed().pipe(
      tap(() => stationDialogRef.close()),
    ).subscribe();

  }

  protected handlingMissingImage = handlingMissingImage;

  protected alertsCount(stationId: StationId): number {
    return this.alertsStore.getUnacknowledgedAlertsCountByStation(stationId);
  }

  protected onAlertsClick(station: Station) {
    this.alertsStore.setFilterTerm(station.name);
    this.router.navigate(['/', 'alerts']);
  }

  protected onTooltipMouseEnter(station: Station) {
    if( station.status === STATUS.DEGRADED) {
      this.tooltipLoading.set(true);
      const parameterFilter: Partial<ParameterFilter> = {
        stationId: [station.id],
      }
      this.parameterService.get(parameterFilter)
      .pipe(
        map((parameters: StationParameter) => {
          if (Object.prototype.hasOwnProperty.call(parameters, station.id)) {
            return parameters[station.id];
          } else {
            return new Array<Parameter>
          }
        }),
        map(parameters => parameters.filter(parameter => parameter.status === PARAMETER_STATUS.ALARM)),
        tap(degradedParameters => {
          this.degradedParametersTooltip.set([...degradedParameters]);
        }),
        tap(() => this.tooltipLoading.set(false)),
        take(1),
      ).subscribe();  
    }
  }

  protected onTooltipMouseLeave() {
    this.tooltipLoading.set(false)
  }
}
