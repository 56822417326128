
<h2 class="title">
    @if (data.mode === MODE.CREATE) {
        <span>Create a new station</span>
    } @else {
        <span>Update station</span>
    }
</h2>
<form #form="ngForm" (ngSubmit)="save(form)" class="station-form-wapper">
    <div class="station-form-content">
        <fieldset class="station-form-group">
            <label for="id">Identifier</label>
            <input placeholder="CDG_R03_Touchdown" id="id" required name="id" [disabled]="data.mode === MODE.UPDATE"
                [ngModel]="stationState.id()" (ngModelChange)="patchState({id: $event})"
            />
        </fieldset>
        <fieldset class="station-form-group">
            <label for="name">Name</label>
            <input placeholder="CDG R03 Touchdown" id="name" required name="name"
                [ngModel]="stationState.name()" (ngModelChange)="patchState({name: $event})"
            />
        </fieldset>
        <fieldset class="station-form-group">
            <label for="latitude">Latitude</label>
            <input placeholder="12.98933" id="latitude" required name="latitude" type="number" step="0.000001"
                [ngModel]="stationState.latitude()" (ngModelChange)="patchState({latitude: $event})"
                va
            />
        </fieldset>
        <fieldset class="station-form-group">
            <label for="name">Longitude</label>
            <input placeholder="4.1232393" id="longitude" required name="longitude" type="number" step="0.000001"
                [ngModel]="stationState.longitude()" (ngModelChange)="patchState({longitude: $event})"
            />
        </fieldset>
        <fieldset class="station-form-group">
            <label for="source">Type</label>
            <select name="source" id="source" name="source" required [ngModel]="stationState.source()" (ngModelChange)="patchState({source: $event})">
                @for (source of ALL_SOURCES; track $index) {
                    <option [ngValue]="source">{{ source }}</option>
                }
            </select>
        </fieldset>
    </div>
    <div class="station-form-actions">
        <button (click)="cancel()" mat-raised-button>Cancel</button>
        <button type="submit" mat-raised-button color="primary" [disabled]="form.form.invalid">Save</button>
    </div>
</form>