import { Component, HostBinding, input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  standalone: true,
  selector: 'dipla-badge',
  template: `
  <span class="dipla-badge">
    <ng-content />
  </span>
  `,
  styleUrl: './badge.component.scss'
})
export class BadgeComponent {

  color = input<ThemePalette | 'none'>('primary');

  @HostBinding('class')
  get hostClass() {
    if (this.color() === 'none')
      return;
    return `${this.color()}-badge`;
  }

}
