import { Pipe, PipeTransform } from '@angular/core';
import { codeToCapitalize } from '../utilities/string-utilities';

@Pipe({
  name: 'code',
  standalone: true,
  pure: true,
})
export class CodePipe implements PipeTransform {

  transform(code: string): string {
    return codeToCapitalize(code);
  }

}
