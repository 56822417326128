<form class="categories-container">
    <mat-form-field class="categories-container">
        <mat-label>Parameters</mat-label>
        <mat-chip-grid #chipGrid aria-label="Parameters selection">
            @for (parameter of parameterStore.selectedParameters(); track parameter.id) {
            <mat-chip-row (removed)="remove(parameter.id)">
                {{parameter.sensorType | sensor: parameterStore.sensors() | lowercase}}
                {{parameter.process | lowercase}}
                {{parameter.frequency | uppercase}}
                <button matChipRemove [attr.aria-label]="'remove ' + parameter.id">
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip-row>
            }
        </mat-chip-grid>
        <input placeholder="Add parameter..." #parameterInput="matChipInput" [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addUnique()"
            #autoTrigger="matAutocompleteTrigger"
            [ngModel]="parameterSearch()" (ngModelChange)="onSearchInputChange($event)"
        />
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
            @for (category of parameterStore.groupByCategoryThenProcess(); track category[0]) {
                <mat-optgroup>
                    <div class="option-category">
                        <img [src]="'../assets/img/category_' + (category[0] | lowercase ) + '.svg'" alt="">
                        <span>{{category[0] | category: parameterStore.categories()}}</span>
                    </div>
                    @for (processMap of category[1]; track processMap[0]) {
                        <mat-optgroup>
                            <div class="process-option">
                                {{processMap[0]}}
                            </div>
                            @for (parameter of processMap[1]; track parameter.id) {
                                <mat-option [value]="parameter.id" class="parameter-option">
                                    {{parameter.sensorType | sensor: parameterStore.sensors() | lowercase}}
                                    {{parameter.frequency | uppercase}}
                                </mat-option>
                            }            
                        </mat-optgroup>
                    }
                </mat-optgroup>
            }
        </mat-autocomplete>
    </mat-form-field>
</form>