import { Measurement, StationMeasurement, UNIT, Unit } from "../models/measurements.models";
import { SENSOR, SensorType } from "../models/stations.models";
import { StationTimeSeries, TimeSeriesItem } from "../models/time-series.models";

const sensorTypeToRoundedConfiguration: Map<SensorType, {pad: number, decimalNumber: number}> = new Map([
  [SENSOR.DEW_POINT, {pad: 2, decimalNumber: 1}],
  [SENSOR.WIND_DIRECTION, {pad: 3, decimalNumber: 0}],
  [SENSOR.WIND_SPEED, {pad: 2, decimalNumber: 0}],
  [SENSOR.TEMPERATURE, {pad: 2, decimalNumber: 1}],
  [SENSOR.RELATIVE_HUMIDITY, {pad: 2, decimalNumber: 0}],
  [SENSOR.PRECIPITATION, {pad: 5, decimalNumber: 2}],
  [SENSOR.MEAN_SEA_LEVEL_PRESSURE, {pad: 6, decimalNumber: 1}],
  [SENSOR.MOR, {pad: 4, decimalNumber: 0}],
  [SENSOR.RUNWAY_VISUAL_RANGE, {pad: 4, decimalNumber: 0}],
  [SENSOR.LOW_CLOUD_LAYER, {pad: 0, decimalNumber: 0}],
  [SENSOR.MIDDLE_CLOUD_LAYER, {pad: 0, decimalNumber: 0}],
  [SENSOR.UPPER_CLOUD_LAYER, {pad: 0, decimalNumber: 0}],
  [SENSOR.AIRPORT_LEVEL_PRESSURE, {pad: 6, decimalNumber: 2}],
  [SENSOR.BGL, {pad: 0, decimalNumber: 0}],
  [SENSOR.SOLAR_RADIATION, {pad: 0, decimalNumber: 0}],
  [SENSOR.SOLAR_DURATION, {pad: 0, decimalNumber: 1}],
])

export function parseMeasureToStringValue(measure: Measurement | StationMeasurement | undefined, unit?: Unit) {
  if (measure) {
    let measureValue;
    if (typeof measure.value === 'string') {
      if (unit === UNIT.CROSS_WIND_COMPONENT || unit === UNIT.CODE) {
        return measure.value;  
      }
      measureValue = Number.parseFloat(measure.value);
    } else {
      measureValue = measure.value;
    }
    const stringConfig = sensorTypeToRoundedConfiguration.get(measure.sensorType)??{pad:4, decimalNumber: 2};
    const multiplicator = Math.pow(10, stringConfig.decimalNumber ?? 0);
    measureValue = Math.round(measureValue * multiplicator) / multiplicator;
    return measureValue.toFixed(stringConfig.decimalNumber).toString().padStart(stringConfig.pad, '0') + ' ' + parseUnit(unit);
  }
  return '';
}

export function parseUnit(unit: Unit | undefined): string {
    switch (unit) {
        case UNIT.CELSIUS:
          return '°C';
        case UNIT.KT:
          return 'KT';
        case UNIT.H_PA:
          return 'hPa';
        case UNIT.PERCENT:
          return '%';
        case UNIT.MILIMETER:
          return  'mm';
        case UNIT.CENTIMETER:
          return 'cm';
          case UNIT.METER:
            return 'm';
          case UNIT.M_BAR:
          return 'mb';
        case UNIT.DEGREE:
          return '°';
        case UNIT.WATTS_PER_METER2:
          return 'W/m²';
        case UNIT.HOUR:
          return 'h';
          default:
          return '';
    }
}

export function parseMeasurementValueToNumberValue(value: string | number): number {
  if(typeof value === 'string') {
    return Number.parseFloat(value);
  }
  if (typeof value === 'number') {
    return value;
  }
  return NaN; 
}

export function insertMeasurements(newMeasurements: Measurement[], initialMeasurements: Measurement[]): Measurement[] {
  const result = [...initialMeasurements]
  newMeasurements.forEach(newMeasurement => {
    const index = result.findIndex((oldMeasure: Measurement) => oldMeasure.stationId === newMeasurement.stationId &&
      oldMeasure.sensorType === newMeasurement.sensorType &&
      oldMeasure.process === newMeasurement.process &&
      oldMeasure.frequency === newMeasurement.frequency
    );
    if (index === -1) {
      result.push({ ...newMeasurement });
    } else {
      if (newMeasurement.timestamp >= result[index].timestamp) {
        result.splice(index, 1, { ...newMeasurement });
      }
    }
  });
  return result;
}

export function createTimeSeriesItem(accumulator: Map<string, StationTimeSeries>, key: string, measurement: Measurement) {
  const item = accumulator.get(key) || { stationId: measurement.stationId, timeSeries: new Array<TimeSeriesItem>() };
  const timeSeriesItem: TimeSeriesItem = [new Date(measurement.timestamp).getTime(), parseMeasurementValueToNumberValue(measurement.value)];
  item.timeSeries.push(timeSeriesItem);
  return item;
}

export const compareTimeSeriesFunction = (a: TimeSeriesItem, b: TimeSeriesItem) => a[0] - b[0];

