<div class="alerts-table-container mat-elevation-z8" >
    <table mat-table [dataSource]="dataSource()" matSort matSortActive="startTimestamp" matSortDirection="desc">

        <!-- Message Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell *matCellDef="let alert"> {{alert.id}} </td>
        </ng-container>

        <!-- Message Column -->
        <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Message </th>
            <td mat-cell *matCellDef="let alert"> {{alert.message}} </td>
        </ng-container>

        <!-- Type Column -->
        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
            <td mat-cell *matCellDef="let alert"> {{alert.typeLabel}} </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
            <td mat-cell *matCellDef="let alert"> {{alert.status}} </td>
        </ng-container>

        <!-- Start Timestamp Column -->
        <ng-container matColumnDef="startTimestamp">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Start </th>
            <td mat-cell *matCellDef="let alert"> {{alert.startTimestamp | date: 'long'}} </td>
        </ng-container>

        <!-- End Timestamp Column -->
        <ng-container matColumnDef="endTimestamp">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> End </th>
            <td mat-cell *matCellDef="let alert"> {{alert.endTimestamp | date: 'long'}} </td>
        </ng-container>

        <!-- Station Column -->
        <ng-container matColumnDef="stationName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Station </th>
            <td mat-cell *matCellDef="let alert"> {{alert.stationName}} </td>
        </ng-container>

        <!-- Parameter Column -->
        <ng-container matColumnDef="parameter">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Parameter </th>
            <td mat-cell *matCellDef="let alert"> {{alert.parameter}} </td>
        </ng-container>

        <!-- CSV File Column -->
        <ng-container matColumnDef="source">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Source </th>
            <td mat-cell *matCellDef="let alert"> {{alert.source}} </td>
        </ng-container>

        <!-- Acknowledged Column -->
        <ng-container matColumnDef="acknowledged">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <button (click)="onAcknowledgeAllAlerts($event)" mat-icon-button matTooltip="Acknowledged all">
                    <mat-icon>playlist_add_check</mat-icon>
                </button>
            </th>
            <td mat-cell *matCellDef="let alert">
                <button (click)="onAcknowledgeAlert(alert)" mat-icon-button [disabled]="alert.acknowledged" matTooltip="Acknowledge this alert">
                    <mat-icon [color]="alert.acknowledged ? 'accent' : 'warn'">done</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let alert; columns: displayedColumns;"
            [ngClass]="{
             'acknowledged' : alert.acknowledged,
             'unacknowledged': !alert.acknowledged,
             'row-threshold-alert': (alert | isThresholdAlert)
            }"
            (click)="onAlertClick(alert)"></tr>
  
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow class="no-data-row">
            <td class="mat-cell" colspan="7">No data matching the filter</td>
        </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" 
        aria-label="Select page of alerts">
    </mat-paginator>

    @if (loading()) {
        <div class="spinner-wrapper">
            <mat-spinner></mat-spinner>
        </div>
    }
</div>
