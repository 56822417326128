import { Injectable, inject } from "@angular/core";
import { MeasurementRxStompService } from "./stomp/measurements-stomp.service";
import { HttpClient } from "@angular/common/http";
import { Measurement, MeasurementConfig, PERIOD, Process } from "../models/measurements.models";
import { Observable, map, mergeWith, scan, shareReplay, startWith } from "rxjs";
import { ObjectValues } from "../models/object-values.models";
import { API_URL } from "src/environments/url";
import { insertMeasurements } from "../utilities/measurement-utilities";
import { getConfigItemParam } from "../utilities/http-utilities";
import { SensorType, StationId } from "../models/stations.models";

type MeasurementDataStream = {
  type: 'MEASURE',
  measures: Array<Measurement>,
};

export const DEFAULT_MEASUREMENT_CONFIG: MeasurementConfig = {
  afterTimestamp: new Date(Date.now() - (1000 * 60 * 10)),
  frequency: [PERIOD.PT10M, PERIOD.PT1M],
  period: [],
  sensorType: [],
  stationId: [],
  process: [],
};

const PARAM_NAME = {
  STATION: 'stationId',
  SENSOR_TYPE: 'sensorType',
  FREQUENCY: 'frequency',
  PROCESS: 'process',
  AFTER_TIMESTAMP: 'afterTimestamp',
} as const;


@Injectable({
  providedIn: 'root',
})
export class MeasurementsService {
  http = inject(HttpClient);
  #wsService = inject(MeasurementRxStompService);

  #realTimeMeasures$ = this.#wsService.connect<MeasurementDataStream>('MEASURE').pipe(
    map(data => data.measures),
    startWith(new Array<Measurement>()),
    shareReplay(1),
  );

  public getLastMeasurements(config: MeasurementConfig = DEFAULT_MEASUREMENT_CONFIG): Observable<Array<Measurement>> {
    const realTimeMeasurementsFiltered$ = this.getRealTimeMeasurementsFiltered(config);
    const params = this.getRequestParams(config);
    return this.http.get<Array<Measurement>>(`${API_URL.MEASUREMENTS}?${params}`)
    .pipe(
      startWith(new Array<Measurement>()),
      mergeWith(realTimeMeasurementsFiltered$),
      scan((acc: Array<Measurement>, measurements: Array<Measurement>) => {
        return insertMeasurements(measurements, acc);
      }, new Array<Measurement>()),
    );
  }

  public getMeasurements(config: MeasurementConfig = DEFAULT_MEASUREMENT_CONFIG): Observable<Array<Measurement>> {
    const realTimeMeasurementsFiltered$ = this.getRealTimeMeasurementsFiltered(config);
    const params = this.getRequestParams(config);
    return this.http.get<Array<Measurement>>(`${API_URL.MEASUREMENTS}?${params}`)
    .pipe(
      startWith(new Array<Measurement>()),
      map(measurements => measurements.filter(measurement => filterByConfig(measurement, config))),
      mergeWith(realTimeMeasurementsFiltered$),
    );
  }

  private getRequestParams(config: MeasurementConfig = DEFAULT_MEASUREMENT_CONFIG): string {
    const afterTimestampParam = getConfigItemParam<ObjectValues<typeof PARAM_NAME>, string>(PARAM_NAME.AFTER_TIMESTAMP, config.afterTimestamp?.toISOString());
    const stationIdParam = getConfigItemParam<ObjectValues<typeof PARAM_NAME>, StationId>(PARAM_NAME.STATION, config.stationId);
    const sensorTypeParam = getConfigItemParam<ObjectValues<typeof PARAM_NAME>, SensorType>(PARAM_NAME.SENSOR_TYPE, config.sensorType);
    const processParam = getConfigItemParam<ObjectValues<typeof PARAM_NAME>, Process>(PARAM_NAME.PROCESS, config.process);
    const lastParam = true;
    const frequencyParam = getConfigItemParam<ObjectValues<typeof PARAM_NAME>, string>(PARAM_NAME.FREQUENCY, config.frequency, lastParam);
    return `${afterTimestampParam}${stationIdParam}${sensorTypeParam}${processParam}${frequencyParam}`;
  }

  private getRealTimeMeasurementsFiltered(config: MeasurementConfig = DEFAULT_MEASUREMENT_CONFIG): Observable<Array<Measurement>> {
    return this.#realTimeMeasures$.pipe(
      map(measurements => measurements.filter(measurement => filterByConfig(measurement, config))),
    );
  }

  private getConfigItemParam<T>(paramName: ObjectValues<typeof PARAM_NAME>, configItem: Array<T> | T, lastParam = false): string {
    let result = '';
    if (Array.isArray(configItem)) {
      if (configItem.length === 0) {
        return result;
      } else {
        result = configItem.reduce((accumulator, currentConfigItem, index) => {
          return accumulator + `${currentConfigItem}${index !== configItem.length - 1 ? ',' : ''}`
        }, `${paramName}=`);
      }
    } else {
      result = `${paramName}=${configItem}`;
    }
    return `${result}${lastParam ? '' : '&'}`;
  }
}

function filterByConfig(measurement: Measurement, config: MeasurementConfig) {
  return (config.frequency.length === 0 || config.frequency.includes(measurement.frequency))
  && (config.process.length === 0 || config.process.includes(measurement.process))
  && (config.stationId.length === 0 || config.stationId.includes(measurement.stationId))
  && (config.sensorType.length === 0 || config.sensorType.includes(measurement.sensorType));
}