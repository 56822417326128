export function partition<T>(arr: Array<T>, fn: (val: T, i: number, arr: Array<T>) => boolean) {
  return arr.reduce(
    (acc, val, i, arr) => {
      acc[fn(val, i, arr) ? 0 : 1].push(val);
      return acc;
    },
    [new Array<T>(), new Array<T>()]
  );
}

export function compareById<T extends {id: string}>(): (a: T, b:T) => number;
export function compareById<T extends {id: number}>(): (a: T, b:T) => number;
export function compareById<T extends {id: number | string}>() {
  return (a: T, b: T) => {
    if (typeof a.id === 'number' && typeof b.id === 'number') {
      return a.id - b.id;
    } else if (typeof a.id === 'string' && typeof b.id === 'string') {
      return a.id.localeCompare(b.id);
    } else return 0;
  }
}
