<div class="card shadow p-2 mb-3 shadow-sm">

  <div class="row card-container">

    <div class="col chart-content justify-content-center align-items-center d-flex">
      <div class="bg-runway justify-content-center align-items-center d-flex" >
        <div class="bg-img" #bgImg>
          <img [ngClass]='"r"+runwayConfig().heading.name' [src]="runwayConfig().backgroundImage" alt=""
            class="img-fluid m-auto d-flex" />
        </div>
      </div>
      <highcharts-chart [Highcharts]="highcharts()" [options]="chartOptions()" [update]="updateHighchart()"/>
    </div>
    <div class="wind-speed-content">
      <div class="min-1"><span>PT1M: <strong>{{vm$().averageOneMinWindSpeed}}KT</strong></span></div>
      <div class="min-10"><span>PT10M: <strong>{{vm$().averageTenMinWindSpeed}}KT</strong></span></div>
    </div>
    <div class="cross-wind-content">
      <div>
        <img ngbTooltip="Cross wind - Average" tooltipClass="custom-tooltip-class" [openDelay]="400"
          src="../assets/img/crosswind_component.svg" alt="CW">
        <span><strong ngbTooltip="Cross wind - Average" tooltipClass="custom-tooltip-class" [openDelay]="400">CW:
            {{vm$().crossWindComponent}}</strong></span>
      </div>
    </div>
    <div class="track-wind-content">
      <div>
        <img src="../assets/img/crosswind_component.svg" alt="TW">
        <span><strong>TW: {{vm$().trackWindComponent}}</strong></span>
      </div>
    </div>
  </div>
  <div class="row p-2">
    <div class="real-time-report container">
      <ng-container [ngTemplateOutlet]="measurementsTemplate" [ngTemplateOutletContext]="{values: {
          oneMinute: vm$().averageOneMinWindDirection,
          tenMinutes: vm$().averageTenMinWindDirection
        }, sensor: 'Wind direction' ,process: 'Average', img: 'wind_direction.svg', unit:'°'}"></ng-container>
      <ng-container [ngTemplateOutlet]="measurementsTemplate" [ngTemplateOutletContext]="{values: {
          oneMinute: vm$().averageOneMinWindSpeed,
          tenMinutes: vm$().averageTenMinWindSpeed
        }, sensor: 'Wind speed' ,process: 'Average', img: 'wind_speed.svg', unit:'KT'}"></ng-container>
      <ng-container [ngTemplateOutlet]="measurementsTemplate" [ngTemplateOutletContext]="{values: {
        oneMinute: vm$().averageOneMinMOR,
        tenMinutes: vm$().averageTenMinMOR
      }, sensor: 'MOR' ,process: 'Average', img: 'mor.svg', unit:'m'}"></ng-container>
      <ng-container [ngTemplateOutlet]="measurementsTemplate" [ngTemplateOutletContext]="{values: {
        oneMinute: vm$().averageOneMinRVR,
        tenMinutes: vm$().averageTenMinRVR
      }, sensor: 'RVR' ,process: 'Average', img: 'rvr.svg', unit:'m'}"></ng-container>
      @if (vm$().averageOneMinBGL) {
      <ng-container [ngTemplateOutlet]="simpleMeasurementTemplate" [ngTemplateOutletContext]="{value: vm$().averageOneMinBGL,
      sensor: 'BGL' ,process: 'Sample', frequency: 'PT1M', img: 'bgl.svg', unit:'cd/m2'}"></ng-container>
      }
      @if (vm$().sampleTenSecondsQFE) {
        <ng-container [ngTemplateOutlet]="simpleMeasurementTemplate" [ngTemplateOutletContext]="{value: vm$().sampleTenSecondsQFE,
        sensor: 'QFE' ,process: 'Sample', frequency: 'PT10S', img: 'qfe.svg', unit:'hPa'}"></ng-container>
      }
      @if (vm$().sampleTenSecondsQFEInches) {
        <ng-container [ngTemplateOutlet]="simpleMeasurementTemplate" [ngTemplateOutletContext]="{value: vm$().sampleTenSecondsQFEInches,
          sensor: 'QFE' ,process: 'Sample', frequency: 'PT10S', img: 'qfe.svg', unit:'inHg'}"></ng-container>
      }
      @if (vm$().sampleTenSecondsQNH) {
        <ng-container [ngTemplateOutlet]="simpleMeasurementTemplate" [ngTemplateOutletContext]="{value: vm$().sampleTenSecondsQNH,
          sensor: 'QNH' ,process: 'Sample', frequency: 'PT10S', img: 'qnh.svg', unit:'hPa'}"></ng-container>
      }
      @if (vm$().sampleTenSecondsQNHInches) {
        <ng-container [ngTemplateOutlet]="simpleMeasurementTemplate" [ngTemplateOutletContext]="{value: vm$().sampleTenSecondsQNHInches,
          sensor: 'QNH' ,process: 'Sample', frequency: 'PT10S', img: 'qnh.svg', unit:'inHg'}"></ng-container>
      }
    </div>
    <div class="real-time-report container">
      @if (vm$().lowCloudLayer) {
      <ng-container [ngTemplateOutlet]="simpleMeasurementTemplate" [ngTemplateOutletContext]="{value: vm$().lowCloudLayer,
      sensor: 'Lower cloud layer' ,process: 'Sample', frequency: 'PT30S', img: 'cloud_layer_1.svg', unit:'ft'}"></ng-container>
      }
      @if (vm$().middleCloudLayer) {
        <ng-container [ngTemplateOutlet]="simpleMeasurementTemplate" [ngTemplateOutletContext]="{value: vm$().middleCloudLayer,
        sensor: 'Middle cloud layer' ,process: 'Sample', frequency: 'PT30S', img: 'cloud_layer_2.svg', unit:'ft'}"></ng-container>
        }
      @if (vm$().upperCloudLayer) {
      <ng-container [ngTemplateOutlet]="simpleMeasurementTemplate" [ngTemplateOutletContext]="{value: vm$().upperCloudLayer,
      sensor: 'Upper cloud layer' ,process: 'Sample', frequency: 'PT30S', img: 'cloud_layer_3.svg', unit:'ft'}"></ng-container>
      }
    </div>
  </div>
</div>
<ng-template #simpleMeasurementTemplate let-sensor="sensor" let-process="process" let-frequency="frequency" let-value="value" let-img="img"
  let-unit="unit">
  <div class="real-time-item" [ngbTooltip]="sensor + ' ' + process + ' ' + frequency" tooltipClass="custom-tooltip-class" [openDelay]="400">
    <img class="real-time-icon" [src]="'../assets/img/'+ img" [alt]="sensor">
    <div class="real-time-values">
      <span class="other-period">{{value + unit || '----'}}</span>
    </div>
  </div>
</ng-template>
<ng-template #measurementsTemplate let-sensor="sensor" let-process="process" let-values="values" let-img="img"
  let-unit="unit">
  <div class="real-time-item" [ngbTooltip]="parameterTooltipTemplate" tooltipClass="custom-tooltip-class"
    [tooltipContext]="{ parameter: sensor + ' - ' + process }" [openDelay]="400">
    <img class="real-time-icon" [src]="'../assets/img/'+ img" [alt]="sensor">
    <div class="real-time-values">
      <span class="min-1">{{values.oneMinute + unit || '----'}}</span>
      <span class="min-10">{{values.tenMinutes + unit || '----'}}</span>
    </div>
  </div>
</ng-template>

<ng-template #parameterTooltipTemplate let-parameter="parameter">
  <div class="parameter-tooltip-container">
    <h6 class="parameter-tooltip">
      {{parameter}}
    </h6>
    <div class="frequencies">
      <span class="">1 minute</span>
      <span class="">10 minutes</span>
    </div>
  </div>
</ng-template>