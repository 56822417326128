import { environment } from './environment';

export const API_URL = {
    MEASUREMENTS: environment.BASE_API_URL + '/data/measures',
    STATIONS: environment.BASE_API_URL + '/data/stations',
    RUNWAYS: environment.BASE_API_URL + '/data/runways',
    TAGS: environment.BASE_API_URL + '/data/tags',
    MEDIAS: environment.BASE_API_URL + '/data/medias',
    SENSORS: environment.BASE_API_URL + '/data/sensor_referential',
    PARAMETERS: environment.BASE_API_URL + '/data/parameters',
    WIND_ROSE: environment.BASE_API_URL + '/data/measures/windrose',
    ALERT_SETTINGS: environment.BASE_API_URL + '/alerts-settings',
    EVENTS: environment.BASE_API_URL + '/events',
    FEATURE_FLAGS: environment.BASE_API_URL + '/feature-flags',
}

const REGISTER = '/register';
const BASE_STREAM_MEASUREMENT = '/data/streams/measures';
const BASE_STREAM_MEASUREMENT_URL = environment.BASE_WS_URL + BASE_STREAM_MEASUREMENT; 
const BASE_STREAM_ALERT = '/events/streams/threshold';
const BASE_STREAM_ALERT_URL = environment.BASE_WS_URL + BASE_STREAM_ALERT;
const BASE_STREAM_PARAMTER = '/data/streams/parameters';
const BASE_STREAM_PARAMETER_URL = environment.BASE_WS_URL + BASE_STREAM_PARAMTER;
const BASE_STREAM_STATION = '/data/streams/stations';
const BASE_STREAM_STATION_URL = environment.BASE_WS_URL + BASE_STREAM_STATION;


export const STREAM_URL = {
    AWOS_BROKER: BASE_STREAM_MEASUREMENT_URL + REGISTER,
    AWOS_TOPIC: BASE_STREAM_MEASUREMENT + '/awos',

    ALERT_BROKER: BASE_STREAM_ALERT_URL + REGISTER,
    ALERT_TOPIC:  BASE_STREAM_ALERT + '',

    PARAMETER_BROKER: BASE_STREAM_PARAMETER_URL + REGISTER,
    PARAMETER_TOPIC: BASE_STREAM_PARAMTER + '/status',

    STATION_BROKER: BASE_STREAM_STATION_URL + REGISTER,
    STATION_TOPIC: BASE_STREAM_STATION + '/status',
}
