import { Component, computed, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { FilterComponent } from 'src/app/shared/ui/filter/filter.component';
import { AlertStore } from '../../shared/services/alerts-store';

@Component({
  selector: 'dipla-alerts-filter',
  standalone: true,
  imports: [
    FilterComponent,
    FormsModule,
    MatSlideToggleModule, 
  ],
  templateUrl: './alerts-filter.component.html',
  styleUrl: './alerts-filter.component.scss',
})
export class AlertsFilterComponent {

  alertsStore = inject(AlertStore);

  filterTerm = this.alertsStore.filterTerm;
  
  acknowledgeAlertsHidden = this.alertsStore.acknowledgeAlertsHidden;
  hideAcknowlededAlertsToggleLabel = computed(() =>
    this.acknowledgeAlertsHidden() ? 
    'Acknowledged alerts hidden' :
    'Hide acknowledged alerts'
  )

  systemAlertsHidden = this.alertsStore.systemAlertsHidden;
  hideSystemAlertsToggleLabel = computed(() =>
    this.systemAlertsHidden() ? 
    'System alerts hidden' :
    'Hide system alerts'
  )

  ongoingAlertsHidden = this.alertsStore.ongoingAlertsHidden;

  onToggleHideAcknowlededAlerts() {
    this.alertsStore.toggleHideAcknowledgeAlerts();
  }

  onToggleHideSystemAlerts() {
    this.alertsStore.toggleHideSystemAlerts();
  }

  onToggleHideOngoingAlerts() {
    this.alertsStore.toggleHideOngoingAlerts();
  }

  onFilterTerm(filterTerm: string) {
    this.alertsStore.setFilterTerm(filterTerm);
  }
}
