<div class="stations-container">
    <dipla-filter class="stations-filter" [filter]="filterTerm()" (filterChange)="filterTerm.set($event)" placeholder="Ex: wind speed PT1M" class="filter"/>
    <div class="stations-actions">
        <button (click)="onAddStations()" mat-raised-button color="primary">
            Add Station
        </button>
    </div>
    <div class="stations-content">
        <div class="stations-table-container mat-elevation-z8">
            <table mat-table [dataSource]="dataSource()" matSort matSortActive="name" matSortDirection="asc">

                <!-- Id Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                    <td mat-cell *matCellDef="let station"> {{station.id}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                    <td mat-cell *matCellDef="let station"> {{station.name}} </td>
                </ng-container>

                <!-- Latitude Column -->
                <ng-container matColumnDef="latitude">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Latitude </th>
                    <td mat-cell *matCellDef="let station"> {{station.latitude}} </td>
                </ng-container>

                <!-- Longitude Column -->
                <ng-container matColumnDef="longitude">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Longitude </th>
                    <td mat-cell *matCellDef="let station"> {{station.longitude}} </td>
                </ng-container>

                <!-- Source Column -->
                <ng-container matColumnDef="source">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
                    <td mat-cell *matCellDef="let station"> {{station.source}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let station; columns: displayedColumns;" (click)="onStationClick(station)"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow class="no-data-row">
                    <td class="mat-cell" colspan="7">No data matching the filter</td>
                </tr>
            </table>

            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" aria-label="Select page of stations">
            </mat-paginator>
        </div>
    </div>
</div>