@for (station of stations(); track $index ){
<div class="mat-elevation-z3">
  <mat-card class="station-card" (click)="onStationCardClick(station)">
    <mat-card-header>
      <mat-card-title-group>
        <mat-card-title>
          <span>{{ station.name }}</span>
          @if (station.unknowledgedAlertCount !== 0) {
            <dipla-badge color="warn" (click)="onAlertsClick(station)">
              {{ station.unknowledgedAlertCount > 99 ? '⚠' : station.unknowledgedAlertCount }}
            </dipla-badge>
          }
        </mat-card-title>
        <mat-card-title [ngClass]="{
            'degraded': station.status === STATUS.DEGRADED,
            'malfunctioning': station.status === STATUS.MALFUNCTIONING,
            'nominal': station.status === STATUS.NORMAL
          }" (mouseenter)="onTooltipMouseEnter(station)" (mouseleave)="onTooltipMouseLeave()" [ngbTooltip]="degradedParameterTemplate"
                    [tooltipContext]="{station: station, parameters: degradedParametersTooltip()}" [disableTooltip]="station.status === STATUS.NORMAL"
                    [tooltipClass]="'custom-tooltip-class ' + station.status.toLowerCase()" [closeDelay]="200">
          {{ station.status | code }}
        </mat-card-title>
        @if (station.lastUpdate.getTime() === station.lastUpdate.getTime()) {
          <mat-card-subtitle class="last-update">
            {{ station.lastUpdate | date:'long' }}
          </mat-card-subtitle>
        }
        <img class="mat-elevation-z2" mat-card-md-image [ngSrc]="'../assets/'+station.media" [alt]="station.name"
          (error)="handlingMissingImage($event)" width="25" height="25" loading="lazy" />
      </mat-card-title-group>
    </mat-card-header>
    <mat-card-content>
      <div class="card-measurements">
        @for(measure of station.measurements; let isLast = $last; track measure.timestamp){
          <div [ngbTooltip]="measurementTooltipTemplate"
            [tooltipContext]="{measurement: measure, sensors: sensorsService.sensors()}"
            tooltipClass="custom-tooltip-class" [openDelay]="400" class="card-measurement">
            <img [src]="'../assets/img/' + (measure.sensorType | lowercase ) + '.svg'" alt="CW">
            <span class="value">{{ measure | valueUnitMeasure:measure.sensorType:sensors() }}</span>
          </div>
          @if (!isLast) {
            <mat-divider [vertical]="true"></mat-divider>
          }
        }
      </div>
    </mat-card-content>
  </mat-card>
</div>
}

<ng-template #measurementTooltipTemplate let-measurement="measurement" let-sensors="sensors">
  <div class="measurement-tooltip-container">
    <h6 class="measurement-tooltip">
      {{measurement.sensorType | sensor:sensors}}
    </h6>
    <div>{{measurement.process | titlecase}} - {{measurement.frequency}}</div>
  </div>
</ng-template>

<ng-template #degradedParameterTemplate let-station="station" let-parameters="parameters">
  @if (station.status === STATUS.DEGRADED) {
      @if (tooltipLoading()) {
          Loading...
      } @else {
          <div>Degraded parameter{{parameters.length > 1 ? 's' : ''}}:
            <div> 
              @for (item of degradedParametersTooltip(); track $index) {
              <p class="degraded-parameter">{{item | parameter:sensors()}}</p>
              }
            </div>
          </div>
      }    
  } @else if (station.status === STATUS.MALFUNCTIONING) {
    All sensors are down / not data received
  }
</ng-template>