import { Directive, ElementRef, effect, inject, input } from '@angular/core';
import { ObjectValues } from '../models/object-values.models';
import { MatDialogRef } from '@angular/material/dialog';
import { DIALOG } from '../constants/dialog.constants';

export const CLOSE_DIALOG_POSITION = {
  TOP_LEFT: 'TOP_LEFT',
  TOP_RIGHT: 'TOP_RIGHT',
} as const;
export type Position = ObjectValues<typeof CLOSE_DIALOG_POSITION>;

const CLOSE_BUTTON_ID = 'closeDialogId';

@Directive({
  selector: '[diplaCloseDialog]',
  standalone: true,
})
export class CloseDialogDirective {

  #elementRef = inject(ElementRef);
  #dialog = inject(MatDialogRef);

  position = input<Position>(CLOSE_DIALOG_POSITION.TOP_RIGHT);
  
  constructor() {
    effect(() => {
      let closeButton = document.getElementById(CLOSE_BUTTON_ID);
      if (closeButton) {
        this.setCloseButton(closeButton, this.position())
      } else {
        closeButton = this.addCloseButton(this.position());
      }
      return closeButton;
    });
  }

  private setCloseButton(closeButton: HTMLElement, position: Position) {
    switch (position) {
      case CLOSE_DIALOG_POSITION.TOP_LEFT:
        closeButton.style.top = '0';
        closeButton.style.left = '0';
        break;
      case CLOSE_DIALOG_POSITION.TOP_RIGHT:
      default:
        closeButton.style.top = '0';
        closeButton.style.right = '0';
    }
  }

  private addCloseButton(position: Position): HTMLElement {
    const divCloseButton = document.createElement('div');
    const closeButton = document.createElement('button');
    closeButton.classList.add(...['btn', 'btn-light', 'btn-close']);
    closeButton.style.position = 'absolute';

    // Set the position based on the input value
    this.setCloseButton(closeButton, position)


    closeButton.addEventListener('click', () => {
      this.#dialog.close(DIALOG.EXIT);
    });

    divCloseButton.appendChild(closeButton);
    divCloseButton.style.zIndex = '1';
    this.#elementRef.nativeElement.appendChild(divCloseButton);
    return divCloseButton;
  }
}
