import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Runway } from '../models/runway.models';
import { API_URL } from 'src/environments/url';
import { shareReplay, startWith } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RunwaysService {

  #http = inject(HttpClient);

  runways$ = this.#http.get<Runway[]>(API_URL.RUNWAYS).pipe(
    startWith(new Array<Runway>()),
    shareReplay(1),
  );
}
