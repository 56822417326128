import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { FilterComponent } from 'src/app/shared/ui/filter/filter.component';
import { AlertSettingsStore } from 'src/app/shared/services/alert-settings-store';

@Component({
  selector: 'dipla-alert-settings-filter',
  standalone: true,
  imports: [
    FilterComponent,
    FormsModule, 
  ],
  templateUrl: './alert-settings-filter.component.html',
  styleUrl: './alert-settings-filter.component.scss',
})
export class AlertSettingsFilterComponent {

  alertSettingsStore = inject(AlertSettingsStore);

  filterTerm = this.alertSettingsStore.filterTerm;

  onFilterTerm(filterTerm: string) {
    this.alertSettingsStore.setFilterTerm(filterTerm);
  }
}
