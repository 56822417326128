import { ChangeDetectionStrategy, Component, inject, input, viewChild } from '@angular/core';
import { COMMA, ENTER} from '@angular/cdk/keycodes';
import { ParameterSelectorStore } from '../../services/parameter-selector-store';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { LowerCasePipe, SlicePipe, UpperCasePipe } from '@angular/common';
import { SensorPipe } from '../../pipes/sensor.pipe';
import { SelectableParameter } from '../../models/measurements.models';
import { ObjectValues } from '../../models/object-values.models';
import { FormsModule } from '@angular/forms';
import { CategoryPipe } from '../../pipes/category.pipe';

export const SELECTOR_ACTION = {
  SELECT: 'SELECT',
  DESELECT: 'DESELECT',
} as const;

export type SelectorAction = ObjectValues<typeof SELECTOR_ACTION>;

export type ParameterSelectionChange = {
  action: SelectorAction,
  items: SelectableParameter[],
}
@Component({
  selector: 'dipla-parameter-selector',
  standalone: true,
  imports: [
    MatListModule, MatExpansionModule, MatIconModule, MatChipsModule, MatAutocompleteModule, MatFormFieldModule, MatSelectModule,
    UpperCasePipe, LowerCasePipe, SlicePipe, SensorPipe, CategoryPipe,
    FormsModule,
  ],
  templateUrl: './parameter-selector.component.html',
  styleUrl: './parameter-selector.component.scss',
  providers: [ParameterSelectorStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParameterSelectorComponent {

  protected separatorKeysCodes: number[] = [ENTER, COMMA];
  protected parameterStore = inject(ParameterSelectorStore);
  
  public selectedParameters = this.parameterStore.selectedParameters;
  public parametersStation = input.required<SelectableParameter[]>();
  public selectionChange = this.parameterStore.lastAction;
  public parameterSearch = this.parameterStore.parameterSearchTerm;
  
  private viewAutocompleteTrigger = viewChild.required(MatAutocompleteTrigger);

  constructor() {
    this.parameterStore.init(this.parametersStation);
  }

  remove(id: number) {
    this.parameterStore.toggleParameter(id);
  }

  addUnique() {
    if (this.parameterStore.filteredParameters().length === 1) {
      const parameterId = this.parameterStore.filteredParameters()[0].id;
      this.parameterStore.toggleParameter(parameterId);
    }
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.parameterStore.toggleParameter(event.option.value);
    setTimeout(() => this.parameterSearch() !== '' ? this.viewAutocompleteTrigger().openPanel() : void 0);
  }

  onSearchInputChange(searchTerm: string) {
    if (typeof searchTerm === 'string') {
      this.parameterStore.filterSearchTerm(searchTerm);
    }
  }
}
