import { Component, computed, inject, input } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatBadgeModule } from '@angular/material/badge';

import { UserAuthenticationComponent } from '../../ui/user-authentication/user-authentication.component';
import { UserProfile } from '../../models/user.model';
import { AlertStore } from 'src/app/shared/services/alerts-store';
import { ConfigStore } from '../../services/config-store';
import { FEATURE_FLAG } from '../../models/configs.model';

@Component({
  selector: 'dipla-header',
  standalone: true,
  imports: [
    MatToolbarModule, MatTabsModule, MatBadgeModule,
    RouterLink, RouterLinkActive, NgOptimizedImage,
    UserAuthenticationComponent,
],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  user = input<UserProfile>();
  
  alertsStore = inject(AlertStore);
  configStore = inject(ConfigStore);

  alertsCount = this.alertsStore.unacknowledgedAlertsCount;
  logoClientEnabled = computed(() => 
    this.configStore.entities().find(feature => feature.code === FEATURE_FLAG.LOGO_CLIENT)?.enabled
  );
}
