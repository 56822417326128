import { Component, inject, computed, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AwosService } from '../shared/services/awos.service';
import { CardRunwayComponent } from './card-runway/card-runway.component';
import { CardWindRoseComponent } from './card-wind-rose/card-wind-rose.component';
import { CardVisibilityComponent } from './card-visibility/card-visibility.component';
import { Heading, TOUCHDOWN_INDEX, MIDDLE_INDEX, END_INDEX, MET_GARDEN_INDEX, Runway } from '../shared/models/runway.models';
import { CardWindSpeedTimeSeriesComponent } from './card-wind-speed-time-series/card-wind-speed-time-series.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIcon } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CardMetGardenComponent } from './card-met-garden/card-met-garden.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dipla-awos-multi-ruwnays',
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule, MatIcon, MatButtonModule,
    CardRunwayComponent, CardWindRoseComponent, CardVisibilityComponent, CardMetGardenComponent,
    NgbTooltipModule,
    CardWindSpeedTimeSeriesComponent,
  ],
  templateUrl: './awos-multi-runways.component.html',
  styleUrls: ['./awos-multi-runways.component.scss']
})
export class AwosMultiRunwaysComponent {

  #awosService = inject(AwosService);

  protected activeHeading = this.#awosService.activeHeading;
  protected runways = this.#awosService.runways;
  protected runway = this.#awosService.activeRunwayViewModel$;
  protected stationOneMeasurements = computed(() => 
    this.#awosService.awosMeasurements().filter(measurement => measurement.stationId === this.runway()?.stations[TOUCHDOWN_INDEX]));
  protected stationTwoMeasurements = computed(() => 
    this.#awosService.awosMeasurements().filter(measurement => measurement.stationId === this.runway()?.stations[MIDDLE_INDEX]));
  protected stationThreeMeasurements =  computed(() => 
    this.#awosService.awosMeasurements().filter(measurement => measurement.stationId === this.runway()?.stations[END_INDEX]));

  protected metGardenMeasurements = computed(() => 
    this.#awosService.awosMeasurements().filter(measurement => measurement.stationId === this.runway()?.stations[MET_GARDEN_INDEX]));

  protected windRoseDataTouchdown$ = this.#awosService.windRoseTouchdown$;
  protected windRoseDataMiddle$ = this.#awosService.windRoseMiddle$;
  protected windRoseDataRollout$ = this.#awosService.windRoseRollout$;
  protected windRoseDataMetGarden$ = this.#awosService.windRoseDataMetGarden$;

  protected windSpeedTimeSeries = this.#awosService.windSpeedTimeSeries$;

  protected openedNav = signal(true);
  protected sidenavIcon = computed(() => {
    return this.openedNav() ? 'chevron_left' : 'chevron_right';
  });

  protected openedMetGarden = signal(false);
  protected sideMetGardenIcon = computed(() => {
    return this.openedMetGarden() ? 'chevron_right' : 'chevron_left';
  });

  onOpenedNav(): void {
    this.openedNav.update(status => !status);
  }

  onOpenedMetGarden(): void {
    this.openedMetGarden.update(status => !status);
    this.openedNav.set(false);
  }

  onSelectedRunway(runway: Runway) {
    this.#awosService.switchRunway(runway.id);
  }

  protected onSwitchActiveRunway(newActiveHeading: Heading){
    this.#awosService.switchActiveHeading(newActiveHeading);
  }
}
