import { Pipe, PipeTransform } from '@angular/core';
import { Category } from '../models/stations.models';

@Pipe({
  name: 'category',
  standalone: true
})
export class CategoryPipe implements PipeTransform {

  transform(categoryCode: string, categories: Category[]): string {
    return categories.find(category => category.code === categoryCode)?.label || '';
  }

}
