import { Duration, PERIOD } from "../models/measurements.models";

export function isEqualDuration(a: Duration, b: Duration): boolean {
    return a === b;
}

export function calculateAfterTimestamp(period: Duration) {
    const afterTimestamp = new Date();
    afterTimestamp.setMinutes(afterTimestamp.getMinutes() - (totalDuration(period) * 40));
    return afterTimestamp;
}

export function totalDuration(period: Duration): number {
    switch (period) {
        case PERIOD.PT24H:
            return 10
        case PERIOD.PT10M:
            return 2;
        case PERIOD.PT1M:
        default:
            return 1;
    }
}
