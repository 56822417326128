import { RxStompConfig } from '@stomp/rx-stomp';
import { environment } from 'src/environments/environment';

export const defaultRxStompConfig: RxStompConfig = {
  // How often to heartbeat?
  // Interval in milliseconds, set to 0 to disable
  heartbeatIncoming: 0,
  heartbeatOutgoing: 2000,

  // Wait in milliseconds before attempting auto reconnect
  // Set to 0 to disable
  reconnectDelay: 200,

  // Will log diagnostics on console
  // It can be quite verbose, not recommended in production
  // Skip this key to stop logging to console
  debug: (msg: string): void => {
    if (!environment.production) console.log(new Date(), msg);
  },
};