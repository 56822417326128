import { Component, Signal, computed, effect, input, signal } from '@angular/core';
import { ECharts, EChartsOption, CustomSeriesRenderItemAPI, CustomSeriesRenderItemParams, SeriesOption } from 'echarts';
import { TimeSeries, TimeSeriesItem, TimeSeriesOptions } from '../../models/time-series.models';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { compareTimeSeriesFunction, parseMeasurementValueToNumberValue } from '../../utilities/measurement-utilities';
import { PROCESS, Unit } from '../../models/measurements.models';
import { SENSOR } from '../../models/stations.models';

const Y_AXIS_OFFSET = 60;
const TIMESTAMP_INDEX = 0;
const VALUE_INDEX = 1;
const SPREAD = 5000;

@Component({
  selector: 'dipla-graphs-series',
  standalone: true,
  imports: [NgxEchartsDirective],
  template: `
    <div class="graph-wrapper">
      <div class="graph" echarts [options]="initChartOptions" (chartInit)="onChartInit($event)"></div>
    </div>
  `,
  providers: [
    provideEcharts(),
  ],
  styles: [`
  .graph-wrapper{
    width: 100%;
    height: 580px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .graph-wrapper .graph{
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  `],
})
export class GraphsSeriesComponent {

  timeSeriesOptions = input.required<TimeSeriesOptions[]>();
  private echartsInstance = signal<ECharts | undefined>(undefined);

  protected initChartOptions = this.getInitOptions();

  private legend: Signal<Pick<EChartsOption, 'legend'>> = computed(() => {
    const newTimeSeriesOptions = this.timeSeriesOptions();
    if (newTimeSeriesOptions.length) {
      let prevUnselectedLegend: {[key: string]: boolean} = {};
      if (this.echartsInstance) {
        const prevOptions = (this.echartsInstance()?.getOption() as EChartsOption) || {};
        if (prevOptions.legend && Array.isArray(prevOptions.legend) && prevOptions.legend[0]) {
            prevUnselectedLegend = prevOptions.legend[0].selected || {}
        }
      }
      const echartsOptions: EChartsOption = {
        legend: {
          itemGap: 25,
          data: newTimeSeriesOptions.map(timeSeriesOption => timeSeriesOption.options.name),
          selected: newTimeSeriesOptions.reduce((selected, timeSeriesOption) => {
            const name = timeSeriesOption.options.name;
            return {
              ...selected,
              [name]: name in prevUnselectedLegend ? prevUnselectedLegend[name] : true,
            };
          }, {})
        },
      };
      return echartsOptions;
    } else {
      return {
        legend: {},
      };
    }
  })

  private series: Signal<Pick<EChartsOption, 'series'>> = computed(() => {
    const newTimeSeriesOptions = this.timeSeriesOptions();
    if (newTimeSeriesOptions.length) {
      const prevOptions = (this.echartsInstance()?.getOption() as EChartsOption) || {};
      const prevYAxis = Array.isArray(prevOptions.yAxis) ? prevOptions.yAxis : (prevOptions.yAxis ? [prevOptions.yAxis] : []);
      const [windSpeedAndDirectionsOptions, otherTimeSeriesOptions] = timeSeriesPartition(newTimeSeriesOptions);
      const series: SeriesOption[] = otherTimeSeriesOptions.map(timeSeriesOption => {
        let yAxisIndex = prevYAxis.findIndex((yAxis) => yAxis?.id === 'yAxis-' + timeSeriesOption.options.parameter?.unit);
        yAxisIndex = yAxisIndex !== -1 ? yAxisIndex : 0;
        return {
          id: timeSeriesOption.options.id,
          name: timeSeriesOption.options.name,
          type: timeSeriesOption.options.parameter?.process === PROCESS.TOTAL ? 'bar' : 'line',
          itemStyle: {
            opacity: timeSeriesOption.options.parameter?.process === PROCESS.TOTAL ? 0.5 : 1,
          },
          sampling: 'lttb',
          yAxisIndex,
          showSymbol: false,
          smooth: 0.3,
          data: timeSeriesOption.timeSeries.map(timeSeriesItem => {
            return {name: timeSeriesItem[0].toString() || '-', value: timeSeriesItem};
          })
        }
      });
      windSpeedAndDirectionsOptions.forEach(windSpeedAndDirectionsOptionsItem => {
        const WIND_SPEED_INDEX = 0;
        const WIND_DIRECTION_INDEX = 1;
        let yAxisIndex = prevYAxis.findIndex((yAxis) => yAxis?.id === 'yAxis-' + windSpeedAndDirectionsOptionsItem[WIND_SPEED_INDEX].options.parameter?.unit);
        yAxisIndex = yAxisIndex !== -1 ? yAxisIndex : 0;
        const windSpeedAndDirectionsTimeSeries = createWindSpeedAndDirectionTimeSeries(windSpeedAndDirectionsOptionsItem);
        const seriesItem: SeriesOption = {
          id: windSpeedAndDirectionsOptionsItem[WIND_DIRECTION_INDEX].options.id,
          name: windSpeedAndDirectionsOptionsItem[WIND_DIRECTION_INDEX].options.name,
          type: 'custom',
          renderItem: function (param: CustomSeriesRenderItemParams, api: CustomSeriesRenderItemAPI) {
            const point = api.coord([
              api.value(0),
              api.value(1),
            ]);
            return {
              type: 'path',
              shape: {
                pathData: 'M31 16l-15-15v9h-26v12h26v9z',
                x: -18 / 2,
                y: -18 / 2,
                width: 18,
                height: 18
              },
              rotation: parseMeasurementValueToNumberValue(api.value(2)) + 90,
              position: point,
              style: api.style({
                stroke: '#555',
                lineWidth: 1
              })
            };
          },
          itemStyle: {
            opacity: windSpeedAndDirectionsOptionsItem[0].options.parameter?.process === PROCESS.TOTAL ? 0.5 : 1,
          },
          yAxisIndex,
          data: windSpeedAndDirectionsTimeSeries.map(timeSeriesItem => {
            return {name: timeSeriesItem[0].toString() || '-', value: timeSeriesItem};
          }),
          z: 10,
        };
        series.push(seriesItem)
      });
      const echartsOptions: EChartsOption = {
        series: series
      };
      return echartsOptions;
    } else {
      return {
        series: [],
      };
    }
  });
  private yAxis: Signal<Pick<EChartsOption, 'yAxis'>> = computed(() => {
    const newTimeSeriesOptions = this.timeSeriesOptions();
    if (newTimeSeriesOptions.length) {      
      const units = newTimeSeriesOptions.reduce((acc, item) => {
        if (item.options.parameter?.unit) {
          if (item.options.parameter.sensorType !== SENSOR.WIND_DIRECTION) {
            acc.add(item.options.parameter?.unit);
          }
        }
        return acc;
      }, new Set<Unit>());
      const echartsOptions: EChartsOption = {
        grid: {
          right: `${Math.trunc(units.size / 2) * 2.5 + 7}%`,
          left: `${Math.trunc((units.size - 1)/ 2) * 2.5 + 7}%`,
        },
        yAxis: Array.from(units).map((unit, index) => (
          {
            type: 'value',
            id: 'yAxis-' + unit,
            name: unit,
            axisLabel: {
              show: true,
              formatter: '{value}',
            },
            position: index % 2 === 1 ? 'right' : 'left',
            offset: Math.trunc(index / 2) * Y_AXIS_OFFSET,
            axisLine: {
              show: true,
            },
            splitLine: {
              show: true,
            },
          }
        ))
      };
      return echartsOptions;
    } else {
      return {
        yAxis: {},
      };
    }
  })
  
  constructor() {
    effect(() => {
      const echartsOptions = {
        tooltip: {
          trigger: 'axis',
          formatter: function(params: {value: number[], seriesName: string, color: string}[]) {
            let tooltipContent = '<div style="display:flex;flex-flow: column wrap;row-gap:3px">';
            tooltipContent += `${new Date(params[0]?.value[0]).toLocaleString()}<br>`;
            for (let i = 0; i < params.length; i++) {
              const color = params[i].color;
              tooltipContent += '<div style="display:flex;flex-flow:row nowwrap;justify-content:space-between;align-items:baseline;column-gap:50px">'
              if ((params[i].seriesName as string).includes(SENSOR.WIND_DIRECTION)) {
                tooltipContent += `<div><span style="color:${color};padding:2px;">●</span><span>${params[i].seriesName}</span></div><b>${params[i].value[2]}</b>`;
              } else {
                tooltipContent += `<div><span style="color:${color};padding:2px;">●</span><span>${params[i].seriesName}</span></div><b>${params[i].value[1]}</b>`;
              }
              tooltipContent += '</div>'
            }
            tooltipContent += '</div>'
            return tooltipContent; // Retourner le contenu du tooltip
          }
        },
        ...this.legend(),
        ...this.yAxis(),
        ...this.series(),
      }
      this.echartsInstance()?.setOption(echartsOptions, { replaceMerge: ['series', 'tooltip', 'yAxis', 'legend' ] });
    })
  }

  protected onChartInit($event: ECharts) {
    this.echartsInstance.set($event);
  }

  private getInitOptions(): EChartsOption {
    const actualTime = new Date().getTime();
    const minusXMinutes = new Date(actualTime - 15).getTime();
    const plusXMinutes = new Date(actualTime + 2).getTime();
    return {
      tooltip: {
        trigger: 'axis'
      },
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: 'none'
          },
          restore: {},
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'time',
        id: 'mainXAxis',
        splitLine: {
          show: false,
        },
      },
      yAxis: 
      {
        type: 'value',
        id: 'mainYAxis',
        max: 14,
        boundaryGap: [0, '100%'],
        splitLine: {
          show: true,
        },
      },
      dataZoom: [
        {
          type: 'inside',
          start: plusXMinutes,
          end: minusXMinutes
        },
        {
          start: plusXMinutes,
          end: minusXMinutes
        }
      ],
     };
  }
}

function timeSeriesPartition(newTimeSeriesOptions: TimeSeriesOptions[]): [[TimeSeriesOptions, TimeSeriesOptions][], TimeSeriesOptions[]] {
  return newTimeSeriesOptions.reduce((acc, currentItem) => {
    currentItem.timeSeries.sort(compareTimeSeriesFunction);
    if (currentItem.options.parameter?.sensorType === SENSOR.WIND_DIRECTION || currentItem.options.parameter?.sensorType === SENSOR.WIND_SPEED) {
      const findIndex = acc[1].findIndex(item => {
        const parameter = item.options.parameter;
        const currentParameter = currentItem.options.parameter;
        return parameter?.frequency === currentParameter?.frequency
        && parameter?.process === currentParameter?.process
        && parameter?.sensorType === (currentParameter?.sensorType === SENSOR.WIND_DIRECTION ? SENSOR.WIND_SPEED : SENSOR.WIND_DIRECTION);
      });
      if (findIndex !== -1) {
        const windTimeSeries = acc[1][findIndex];
        if (windTimeSeries.options.parameter?.sensorType === SENSOR.WIND_SPEED){
          acc[0].push([windTimeSeries, currentItem]);
        } else {
          acc[1].splice(findIndex, 1, currentItem); // replace wind direction time series by wind speed in other time series
          acc[0].push([currentItem, windTimeSeries]);
        }
      } else {
        acc[1].push(currentItem);
      }
    } else {
      acc[1].push(currentItem);
    }
    return acc;
  }, [new Array<[TimeSeriesOptions, TimeSeriesOptions]>(), new Array<TimeSeriesOptions>()]);
}

function createWindSpeedAndDirectionTimeSeries([windSpeedTimeSeriesOption, windDirectionTimeSeriesOption]: [TimeSeriesOptions, TimeSeriesOptions]): TimeSeries {
  if (windDirectionTimeSeriesOption.timeSeries.length === 0) {
    return [];
  }
  const timeSeries: TimeSeries = [];
  let indexWindDirection = 0;

  let previousWindDirection: TimeSeriesItem | null = null;
  windSpeedTimeSeriesOption.timeSeries.forEach(windSpeedTimeSeriesItem => {
    const windDirectionTimeSeries = windDirectionTimeSeriesOption.timeSeries;
    for (; indexWindDirection < windDirectionTimeSeries.length; indexWindDirection++) {
      const windDirectionTimeSeriesItem = windDirectionTimeSeries[indexWindDirection];
      if ( windDirectionTimeSeriesItem[TIMESTAMP_INDEX] >= windSpeedTimeSeriesItem[TIMESTAMP_INDEX] ) {
        if (Math.abs(windDirectionTimeSeriesItem[TIMESTAMP_INDEX] - windSpeedTimeSeriesItem[TIMESTAMP_INDEX]) <= SPREAD) {
          if (previousWindDirection) {
            if (Math.abs(windDirectionTimeSeriesItem[TIMESTAMP_INDEX] - windSpeedTimeSeriesItem[TIMESTAMP_INDEX]) <= Math.abs(previousWindDirection[TIMESTAMP_INDEX] - windSpeedTimeSeriesItem[TIMESTAMP_INDEX])) {
              timeSeries.push([...windSpeedTimeSeriesItem, windDirectionTimeSeriesItem[VALUE_INDEX]]);
            } else {
              timeSeries.push([...windSpeedTimeSeriesItem, previousWindDirection[VALUE_INDEX]]);
            }
          } else {
            timeSeries.push([...windSpeedTimeSeriesItem, windDirectionTimeSeriesItem[VALUE_INDEX]]);
          }
        } else {
          if (previousWindDirection) {
            timeSeries.push([...windSpeedTimeSeriesItem, previousWindDirection[VALUE_INDEX]]);
          }
        }
        previousWindDirection = [...windDirectionTimeSeriesItem];
        break;
      } else {
        if (indexWindDirection === windDirectionTimeSeries.length - 1) {
          if (Math.abs(windSpeedTimeSeriesItem[TIMESTAMP_INDEX] - windDirectionTimeSeriesItem[TIMESTAMP_INDEX]) <= SPREAD) {
            timeSeries.push([...windSpeedTimeSeriesItem, windDirectionTimeSeriesItem[VALUE_INDEX]]);
          }
        }
        previousWindDirection = [...windDirectionTimeSeriesItem];
      }
    }
  });
  return timeSeries;
}
