import { DateISOString, Duration, Process, Unit } from "./measurements.models";
import { ObjectValues } from "./object-values.models";
import { SensorType, StationId } from "./stations.models";

export type AlertDataTable = Array<AlertData>;

export type AlertData = {
    id: number,
    message: string,
    category?: AlertCategory,
    type?: AlertType,
    typeLabel: string,
    startTimestamp: DateISOString,
    endTimestamp?: DateISOString,
    status: string,
    stationId: StationId,
    stationName: string,
    parameter: string,
    source: string,
    acknowledged: boolean,
}

export const ALERT_CATEGORY = {
    FUNCTIONAL: 'FUNCTIONAL',
    SYSTEM: 'TECHNICAL',
} as const;
export type AlertCategory = ObjectValues<typeof ALERT_CATEGORY>;

export const ALERT_STATUS = {
    ONGOING: 'ONGOING',
    COMPLETED: 'COMPLETED',
    CLOSED: 'CLOSED',
} as const;
export type AlertStatus = ObjectValues<typeof ALERT_STATUS>;

export const ALERT_TYPE = {
    MAXIMUM_THRESHOLD: 'MAXIMUM_THRESHOLD',
    MINIMUM_THRESHOLD: 'MINIMUM_THRESHOLD',
    ERROR: 'ERROR',
    WARNING: 'WARNING',
    INFO: 'INFO',
} as const;
export type AlertType = ObjectValues<typeof ALERT_TYPE>;

export type Alert = ThresholdAlert | SystemAlert;

interface AlertBase {
    id: number,
    content: string,
    startTimestamp: DateISOString,
    endTimestamp?: DateISOString,
    status: AlertStatus,
    acknowledged: boolean,
}

export interface ThresholdAlert extends AlertBase {
    category?: 'FUNCTIONAL',
    type?: 'MINIMUM_THRESHOLD' | 'MAXIMUM_THRESHOLD',
    stationId: StationId,
    sensorType: SensorType,
    frequency: Duration,
    process: Process,
    lastNotification?: DateISOString,
}

export interface SystemAlert extends AlertBase {
    category?: 'TECHNICAL',
    type?: 'ERROR' | 'WARNING' | 'INFO',
    fileName: string,
}

export const ALERT_SETTING_STATUS = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    NOT_CREATED: 'NOT_CREATED',
} as const;
export type AlertSettingStatus = ObjectValues<typeof ALERT_SETTING_STATUS>;

export type AlertSettingData = {
    id: number,
    stationId: StationId,
    stationName: string,
    parameter: string,
    unit: Unit,
    maxThreshold?: number,
    minThreshold?: number,
    status: AlertSettingStatus,
    emailNotification: boolean,
    pushNotification: boolean,
}

export const ALERT_SETTING_NOTIFICATION = {
    EMAIL: 'EMAIL',
    PUSH: 'PUSH',
} as const;
export type AlertSettingNotification = ObjectValues<typeof ALERT_SETTING_NOTIFICATION>;

export const ALERT_SETTING_BROADCAST_TYPE = {
    ALL: 'ALL',
    SELF: 'SELF',
} as const;
export type AlertSettingBroadcastType = ObjectValues<typeof ALERT_SETTING_BROADCAST_TYPE>;

export type AlertSetting = {
    readonly id: number,
    stationId: StationId,
    sensorType: SensorType,
    frequency: Duration,
    process: Process,
    maxThreshold?: number,
    minThreshold?: number,
    status: AlertSettingStatus,
    emailNotification: boolean,
    pushNotification: boolean,
}

export type AlertSettingDto = {
    readonly id: number,
    stationId: StationId,
    sensorType: SensorType,
    frequency: Duration,
    process: Process,
    maxThreshold?: number,
    minThreshold?: number,
    active: boolean,
    emailNotification: boolean,
    pushNotification: boolean,
}
