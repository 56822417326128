<div class="value-container">
    @if ((editMode())) {
        <input #input [type]="type" [value]="value() ?? ''"
            (blur)="onBlur($event)" (keypress)="onInputKeypress($event)"
        />
    } @else if (Number.isSafeInteger(value())) {
        <div class="value" (click)="onEdit()" tabindex="0" (keypress)="onKeypress($event)">
            {{ value() }}
        </div>
    } @else {
        <mat-icon (click)="onEdit()" aria-hidden="false"fontIcon="edit" tabindex="0" (keypress)="onKeypress($event)"></mat-icon>
    }
</div>
