import { Duration, Process } from "../models/measurements.models";
import { Sensor } from "../models/stations.models";

type TransformParameter = {
    sensor: Sensor | undefined,
    process: Process | undefined,
    frequency: Duration | undefined,
}

export function transformParameter(parameter: TransformParameter): string {
    const sensorLabel = parameter.sensor?.label?.toUpperCase() || '';
    const frequencyLabel = parameter.frequency;
    const processLabel = parameter.process?.toUpperCase();
    return `${sensorLabel} ${processLabel} ${frequencyLabel}`;
}
