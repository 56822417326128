import { computed, inject } from '@angular/core';
import { patchState, signalStore, type, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { addEntity, updateAllEntities, updateEntity, withEntities } from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { catchError, debounceTime, distinctUntilChanged, pipe, switchMap, tap } from 'rxjs';
import { ALERT_CATEGORY, ALERT_STATUS, ALERT_TYPE, Alert, AlertData, ThresholdAlert } from '../models/alerts.model';
import { AlertsService } from './alerts.service';
import { SensorService } from './sensor.service';
import { transformParameter } from '../utilities/parameter.utilities';
import { codeToCapitalize, toCapitalize } from '../utilities/string-utilities';
import { isThresholdAlert } from '../utilities/alerts-utilities';
import { StationId } from '../models/stations.models';
import { StationStore } from './stations-store';

export const AlertStore = signalStore(
    { providedIn: 'root' },
    withState({
        loading: false,
        acknowledgeAlertsHidden: true,
        systemAlertsHidden: true,
        ongoingAlertsHidden: true,
        filterTerm: '',
    }),
    withEntities(
        { entity: type<Alert>(), collection: 'alerts' },
    ),
    withComputed(({ alertsEntities }) => ({
        unacknowledgedAlertsIds: computed(() => alertsEntities()
            .filter(alert => !alert.acknowledged)
            .map(alert => alert.id)
        )
    })),
    withComputed(({ unacknowledgedAlertsIds }) => ({
        unacknowledgedAlertsCount: computed(() => unacknowledgedAlertsIds().length),
    })),
    withMethods((store) => {
        const alertsService = inject(AlertsService);
        return {
            setFilterTerm: rxMethod<string>(
                pipe(
                    debounceTime(300),
                    distinctUntilChanged(),
                    tap((filterTerm: string) => patchState(store, { filterTerm })),
                )
            ),
            toggleHideAcknowledgeAlerts() {
                patchState(store, ({ acknowledgeAlertsHidden }) => ({ acknowledgeAlertsHidden: !acknowledgeAlertsHidden }));
            },
            toggleHideSystemAlerts() {
                patchState(store, ({ systemAlertsHidden }) => ({ systemAlertsHidden: !systemAlertsHidden }));
            },
            toggleHideOngoingAlerts() {
                patchState(store, ({ ongoingAlertsHidden }) => ({ ongoingAlertsHidden: !ongoingAlertsHidden }));
            },
            getUnacknowledgedAlertsCountByStation(stationId: StationId) {
                return store.alertsEntities()
                    .filter(alert => isThresholdAlert(alert) && !alert.acknowledged && alert.stationId === stationId)
                    .length;
            },
            load: rxMethod<void>(pipe(
                tap(() => patchState(store, { loading: true })),
                switchMap(() => alertsService.getAll()),
                tap(() => patchState(store, { loading: false })),
                tap(alerts => {
                    alerts.forEach(alert => {
                        if (store.alertsIds().find(id => id === alert.id)) {
                            patchState(store, updateEntity({id: alert.id, changes: alert}, { collection: 'alerts' }))
                        } else {
                            patchState(store, addEntity(alert, { collection: 'alerts' }));
                        }
                    });
                }),
            )),                
        }
    }),
    withMethods((store) => {
        const alertsService = inject(AlertsService);
        return {
            acknowledgeAlert: rxMethod<number>(pipe(
                tap(alertId => patchState(store, updateEntity({ id: alertId, changes: { acknowledged: true } }, { collection: 'alerts' }))),
                switchMap(alertId => alertsService.acknowledge(alertId).pipe(catchError((error) => {
                    patchState(store, updateEntity({ id: alertId, changes: { acknowledged: false } }, { collection: 'alerts' }));
                    throw error;
                }))),
            )),
            acknowledgeAllAlerts: rxMethod<void>(pipe(
                switchMap(() => alertsService.acknowledgeAll(store.unacknowledgedAlertsIds())),
                tap(() => patchState(store, updateAllEntities({ acknowledged: true }, { collection: 'alerts' }))),
            )),
        }
    }),
    withComputed(({ acknowledgeAlertsHidden, systemAlertsHidden, ongoingAlertsHidden, alertsEntities }) => {
        const stationsStore = inject(StationStore);
        const sensorSerivce = inject(SensorService);
        function extractParameter(alert: ThresholdAlert): string {
            const sensor = sensorSerivce.sensors().find(sensor => sensor.type === alert.sensorType);
            if (sensor && alert.process && alert.frequency) {
                const parameter = {
                    sensor,
                    process: alert.process,
                    frequency: alert.frequency,
                }
                return transformParameter(parameter);
            } else {
                return '';
            }
        }
        return {
            alertDataTable: computed(() => {
                const stations = stationsStore.stations();
                return [
                    ...alertsEntities()
                    .filter(alert => !acknowledgeAlertsHidden() || (acknowledgeAlertsHidden() && !alert.acknowledged))
                    .filter(alert => !systemAlertsHidden() || (systemAlertsHidden() && !(alert.category === ALERT_CATEGORY.SYSTEM)))
                    .filter(alert => !ongoingAlertsHidden() || (ongoingAlertsHidden() && alert.status === ALERT_STATUS.ONGOING))
                    .map(alert => {
                        let type: string = alert.category === ALERT_CATEGORY.SYSTEM ? 'SYSTEM' : alert.type || ALERT_TYPE.INFO;
                        type = toCapitalize(type);
                        return {
                            id: alert.id,
                            message: alert.content,
                            typeLabel: codeToCapitalize(type),
                            acknowledged: alert.acknowledged,
                            status: codeToCapitalize(alert.status),
                            parameter: isThresholdAlert(alert) ? extractParameter(alert) : '',
                            source: isThresholdAlert(alert) ? `` : alert.fileName,
                            stationId: isThresholdAlert(alert) ? alert.stationId : '',
                            stationName: isThresholdAlert(alert) ? stations.find(station => station.id === alert.stationId)?.name || '' : '',
                            startTimestamp: alert.startTimestamp,
                            endTimestamp: alert.endTimestamp,
                            category: alert.category,
                            type: alert.type,
                        } satisfies AlertData;
                    })
                ];
            }),
        }
    }),
    withHooks({
        onInit(store) {
            store.load();
        },
    })
);
