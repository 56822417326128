import { computed, inject } from '@angular/core';
import { patchState, signalStore, type, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { setAllEntities, withEntities } from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { catchError, combineLatest, debounceTime, distinctUntilChanged, of, pipe, shareReplay, switchMap, tap } from 'rxjs';
import { ALERT_SETTING_STATUS, AlertSetting, AlertSettingData } from '../models/alerts.model';
import { SensorService } from './sensor.service';
import { transformParameter } from '../utilities/parameter.utilities';
import { AlertSettingsService } from './alert-settings.service';
import { ParametersService } from './parameters.service';
import { compareById } from '../utilities/array-utilities';
import { StationStore } from './stations-store';
import { Parameter } from '../models/measurements.models';
import { SENSOR, SensorType, StationId } from '../models/stations.models';

export const AlertSettingsStore = signalStore(
    { providedIn: 'root' },
    withState({
        loading: false,
        filterTerm: '',
    }),
    withEntities(
        { entity: type<AlertSetting>(), collection: 'alertSetting' },
    ),
    withMethods((store) => {
        const alertSettingsService = inject(AlertSettingsService);
        const parameterService = inject(ParametersService);

        const EXCLUDE_SENSORS: [SensorType] = [SENSOR.PRESENT_WEATHER]; 
        function isExcludeParameter(parameter: Parameter): boolean {
            return EXCLUDE_SENSORS.includes(parameter.sensorType);
        }

        function mustAddAlertSetting(stationId: StationId, parameter: Parameter, alertSettings: AlertSetting[]): boolean {
            const NOT_FOUND = -1;
            if (isExcludeParameter(parameter)) {
                return false;
            }
            return alertSettings.findIndex(alertSetting => 
                alertSetting.stationId === stationId &&
                alertSetting.sensorType === parameter.sensorType &&
                alertSetting.process === parameter.process &&
                alertSetting.frequency === parameter.frequency
            ) === NOT_FOUND;
        }

        return {
            setFilterTerm: rxMethod<string>(
                pipe(
                    debounceTime(300),
                    distinctUntilChanged(),
                    tap((filterTerm: string) => patchState(store, { filterTerm })),
                )
            ),
            load: rxMethod<void>(pipe(
                switchMap(() => combineLatest([
                    alertSettingsService.findAll().pipe(
                        catchError(() => of(new Array<AlertSetting>())),
                    ),
                    parameterService.get().pipe(shareReplay(1))
                ])),
                tap(([alertSettings, parameters]) => {
                    const entities = [...alertSettings.sort(compareById<AlertSetting>())];
                    for (const [stationId, stationParameters] of Object.entries(parameters)) {
                        if (Object.prototype.hasOwnProperty.call(parameters, stationId)) {
                            stationParameters.forEach(stationParameter => {
                                if (mustAddAlertSetting(stationId, stationParameter, alertSettings)) {
                                    entities.push({
                                        id: (-1) * stationParameter.id,
                                        stationId,
                                        sensorType: stationParameter.sensorType,
                                        frequency: stationParameter.frequency,
                                        process: stationParameter.process,
                                        status: ALERT_SETTING_STATUS.NOT_CREATED,
                                        emailNotification: false,
                                        pushNotification: false,
                                    });
                                }
                            });
                        }
                    }
                    patchState(store, setAllEntities(entities, { collection: 'alertSetting' }))
                }),
                tap(() => patchState(store, { loading: false })),
            )),
        }
    }),
    withComputed(({ alertSettingEntities }) => {
        const stationsStore = inject(StationStore);
        const sensorSerivce = inject(SensorService);

        function extractParameter(alert: AlertSetting): string {
            const sensor = sensorSerivce.sensors().find(sensor => sensor.type === alert.sensorType);
            if (sensor && alert.process && alert.frequency) {
                const parameter = {
                    sensor,
                    process: alert.process,
                    frequency: alert.frequency,
                }
                return transformParameter(parameter);
            } else {
                return '';
            }
        }
        return {
            alertSettingDataTable: computed<AlertSettingData[]>(() => {
                const stations = stationsStore.stations();
                return [...alertSettingEntities()
                    .map(alertSetting => ({
                        id: alertSetting.id,
                        stationId: alertSetting.stationId,
                        stationName: stations.find(station => station.id === alertSetting.stationId)?.name || '',
                        parameter: extractParameter(alertSetting),
                        unit: sensorSerivce.getUnitBySensorCode(alertSetting.sensorType),
                        maxThreshold: alertSetting.maxThreshold,
                        minThreshold: alertSetting.minThreshold,
                        status: alertSetting.status ?? ALERT_SETTING_STATUS.NOT_CREATED,
                        emailNotification: alertSetting.emailNotification,
                        pushNotification: alertSetting.pushNotification,
                    }))
                ];
            }),
        }
    }),
    withMethods((store) => {
        const alertSettingsService = inject(AlertSettingsService);
        return {
            update: rxMethod<{id: string, changes: Partial<AlertSettingData>}>(pipe(
                switchMap(({id, changes}) => {
                    const alertSetting = store.alertSettingEntityMap()[id];
                    if (alertSetting) {
                        if (alertSetting.status === ALERT_SETTING_STATUS.NOT_CREATED) {
                            changes.status = ALERT_SETTING_STATUS.ACTIVE;
                        }
                        if (!{ ...alertSetting, ...changes }.maxThreshold! && !{ ...alertSetting, ...changes }.minThreshold!) {
                            changes.status = ALERT_SETTING_STATUS.INACTIVE;
                        }
                    }
                    if (alertSetting.status === ALERT_SETTING_STATUS.NOT_CREATED) {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const { ['id']: idToOmit, ...alertSettingCreate} = { ...alertSetting, ...changes };  
                        return alertSettingsService.create(alertSettingCreate);
                    } else {
                        return alertSettingsService.update({ ...alertSetting, ...changes });
                    }
                }),
                tap(() => store.load()),
            ))
        }
    }),
    withHooks({
        onInit(store) {
            patchState(store, { loading: true })
            store.load();
        },
    })
);
