import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { UNIT, Unit } from '../models/measurements.models';
import { Category, Sensor, SensorType } from '../models/stations.models';
import { API_URL } from 'src/environments/url';

@Injectable({
  providedIn: 'root'
})
export class SensorService {

  #http = inject(HttpClient);

  sensors = signal<Sensor[]>([]);
  categories = signal<Category[]>([]);

  constructor() {
    this.#http.get<Sensor[]>(API_URL.SENSORS).subscribe(data => {
      this.sensors.set(data);
      this.categories.set(Array.from(new Set(data.map(sensor => sensor.category))));
    });
  }

  getLabel(sensorType: SensorType): string {
    return this.sensors().find(sensor => sensor.type === sensorType)?.label || '-';
  }

  getUnitBySensorCode(sensorCode: string): Unit {
    return this.sensors().find(sensor => sensor.type === sensorCode)?.unit || UNIT.UNKNOWN;
  }

  getCategoryCode(sensorType: SensorType): string {
    return this.sensors().find(sensor => sensor.type === sensorType)?.category.code || '-';
  }

  getCategoryLabel(categoryCode: string): string {
    return this.categories().find(category => category.code === categoryCode)?.label || '-';
  }
}
