import { RxStomp, RxStompConfig } from "@stomp/rx-stomp";
import { defaultRxStompConfig } from "./default-rx-stomp.config";

export function rxStompServiceFactory<T extends RxStomp>(RxStompService: {new(): T}, config: RxStompConfig): T {
  const rxStomp = new RxStompService();
  rxStomp.configure({
    ...defaultRxStompConfig,
    ...config,
  });
  rxStomp.activate();
  return rxStomp;
}