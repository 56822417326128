import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { Observable, catchError, from, switchMap } from "rxjs";
import { environment } from "src/environments/environment";

export function authorizationTokenInterceptor(
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
    const keycloak = inject(KeycloakService);
    return from(keycloak.getToken()).pipe(
        switchMap(token => {
            req = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${token}`),
                withCredentials: true,
            });
            return next(req);
        }),
        catchError(err => {
            if (err instanceof HttpErrorResponse) {
                switch (err.status) {
                    case 401:
                        keycloak.logout(environment.HOME_URL);
                        break;
                }
            }
            throw err;
        }),
    );
}
