import { Pipe, PipeTransform } from '@angular/core';
import { UserProfile } from '../models/user.model';

@Pipe({
  name: 'fullname',
  standalone: true
})
export class UserFullnamePipe implements PipeTransform {

  transform(user: UserProfile | undefined): string {
    if (!user) return 'UNKNOW USER';
    let fullname = '';
    if (user.firstName) {
      fullname += user.firstName[0].toUpperCase() + user.firstName.slice(1).toLowerCase() + ' ';
    }
    if (user.lastName) {
      fullname += user.lastName.toUpperCase();
    }
    if (!user.lastName && !user.firstName) {
      fullname += user.username;
    }
    return fullname;
  }

}
