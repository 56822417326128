import { Injectable, Signal, inject, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { KeycloakService } from 'keycloak-angular';
import { EMPTY, from, switchMap } from 'rxjs';

import { UserProfile } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  authenticateService = inject(KeycloakService);

  authenticated = signal<boolean>(false);
  #authenticated$ = toObservable(this.authenticated);

  user: Signal<UserProfile | undefined> = toSignal(
    this.#authenticated$.pipe(
      switchMap(authenticated => {
        if (authenticated) {
          return from(this.authenticateService.loadUserProfile());
        } else {
          return EMPTY;
        }
      }),
    )
  );

  public setAuthenticated(authenticated: boolean): void {
    this.authenticated.set(authenticated);
  }
}
