import { Attribute, Component, ElementRef, effect, model, signal, viewChild } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { parseIntOrUndefined } from '../../utilities/number-utilities';

@Component({
  selector: 'dipla-input',
  standalone: true,
  imports: [
    MatIcon,
  ],
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
})
export class InputComponent {
  Number = Number;

  value = model<number | undefined>();

  protected editMode = signal<boolean>(false);
  private input = viewChild<ElementRef>('input');

  constructor(@Attribute('type') protected type: string = 'text') {
    effect(() => {
      if (this.input()) {
        this.input()?.nativeElement.focus();
        this.input()?.nativeElement.select();
      }
    })
  }
  
  onEdit() {
    this.editMode.set(true);
  }

  onBlur(event: FocusEvent){
    const inputValue = parseIntOrUndefined((event.target as HTMLInputElement)?.value)
    this.value.set(inputValue);
    this.editMode.set(false);
  }

  onInputKeypress(event: KeyboardEvent) {
    if (event.code === 'Enter') {
      const inputValue = parseIntOrUndefined((event.target as HTMLInputElement)?.value)
      this.value.set(inputValue);
      this.editMode.set(false);
    }
  }

  onKeypress(event: KeyboardEvent) {
    if (event.code === 'Space') {
      this.editMode.set(true);  
    }
  }

}
