import { Injectable, inject } from "@angular/core";
import { Observable, catchError, map, mergeWith, of, shareReplay, startWith, tap } from "rxjs";
import { ALERT_STATUS, Alert, ThresholdAlert } from "../models/alerts.model";
import { API_URL } from "src/environments/url";
import { HttpClient } from "@angular/common/http";
import { AlertRxStompService } from "./stomp/alert-stomp.service";
import { isThresholdAlert } from "../utilities/alerts-utilities";
import { NotificationService } from "./notification.service";
import { SnackBarComponent } from "../ui/snack-bar/snack-bar.component";
import { AlertSnackBarComponent } from "../ui/alert-snack-bar/alert-snack-bar.component";
import { StationStore } from "./stations-store";
import { NOTIFICATION_STATUS } from "../models/notification.models";

type AlertDataStream = Array<Alert>;


export type UpdateAcknoledgedPropertiesRequest = {
    ids: Array<number>;
    acknowledged: boolean;
}
@Injectable(
    { providedIn: 'root' }
) export class AlertsService {

    private http = inject(HttpClient);
    private notificationService = inject(NotificationService);
    private wsService = inject(AlertRxStompService);
    private stationsService = inject(StationStore);

    private realTimeAlerts$ = this.wsService.connect<AlertDataStream>().pipe(
        tap(alerts => {
            const thresholdAlertsCount = alerts.reduce((count: number, currentAlert: Alert) => isThresholdAlert(currentAlert) ? count + 1 : count, 0);
            if (thresholdAlertsCount > 1) {
                this.notificationService.openNotification(SnackBarComponent, {label: '', message: 'New alerts'});
            } else if (thresholdAlertsCount === 1 ) {
                const alert = alerts[0] as ThresholdAlert;
                const stationName = this.stationsService.stations().find(station => station.id === alert.stationId)?.name || '';
                this.notificationService.openNotification(AlertSnackBarComponent, {
                    label: stationName,
                    message: `${alert.status !== ALERT_STATUS.ONGOING ? alert.status + '\n' : ''}${alert.content}`,
                    status: alert.status !== ALERT_STATUS.ONGOING ? NOTIFICATION_STATUS.SUCCESS : NOTIFICATION_STATUS.ALERT,
                    alert,
                },);
            }
        }),
        shareReplay(1),
    );
    
    public getAll(): Observable<Alert[]> {
        const afterTimestamp = new Date();
        afterTimestamp.setMonth(afterTimestamp.getMonth() - 1);  
        return this.http.get<Array<Alert>>(`${API_URL.EVENTS}?afterTimestamp=${afterTimestamp.toISOString()}`)
            .pipe(
                catchError(() => of(new Array<Alert>())),
                startWith(new Array<Alert>()),
                mergeWith(this.realTimeAlerts$),
            );
    }

    public acknowledge(alertId: number): Observable<void> {
        return this.acknowledgeAll([alertId]);
    }

    public acknowledgeAll(alertIds: number[]): Observable<void> {
        const requestBody = {
            ids: [...alertIds],
            acknowledged: true,
        } satisfies UpdateAcknoledgedPropertiesRequest;
        return this.http.put(`${API_URL.EVENTS}/properties/acknowledged`, requestBody).pipe(map(() => void 0));
    }
}
