import { computed, inject } from '@angular/core';
import { SENSOR, SOURCE, Station, StationDTO, StationId, StationStatusDataStream } from '../models/stations.models';
import { map, pipe, switchMap, tap } from 'rxjs';
import { DateISOString, Measurement, MeasurementConfig, PERIOD, PROCESS } from '../models/measurements.models';
import { MeasurementsService, DEFAULT_MEASUREMENT_CONFIG } from './measurements.service';
import { ParametersService } from './parameters.service';
import { StationRxStompService } from './stomp/station-stomp.service';
import { MESSAGE_TYPE } from '../models/rx-stomp.models';
import { patchState, signalStore, type, withComputed, withHooks, withMethods } from '@ngrx/signals';
import { entityConfig, setAllEntities, updateEntity, withEntities } from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { compareStationPriority } from '../utilities/station.utilities';
import { StationsService } from './stations.service';

const lastUpdateByStationConfig = entityConfig({
  entity: type<{stationId: StationId, lastUpdate: Date}>(),
  collection: 'lastUpdateByStation',
  selectId: (item) => item.stationId,
});

const measurementsStationConfig = entityConfig({
  entity: type<{stationId: StationId, lastMeasurements: Measurement[]}>(),
  collection: 'lastMesaurementsByStation',
  selectId: (item) => item.stationId,
});

export const StationStore = signalStore(
  { providedIn: 'root' },
  withEntities({ entity: type<Station>() }),
  withEntities(lastUpdateByStationConfig),
  withEntities(measurementsStationConfig),
  withComputed(({ entities }) => ({
    stations: computed(() => entities().sort(compareStationPriority)),
    stationIdsAwos: computed(() => entities()
      .filter(station => station.source === SOURCE.AWOS)
      .map(station => station.id)
    )
  })),
  withMethods((store) => {
    return {
      updateLastUpdateValue: rxMethod<{lastUpdate: Date, stationId: StationId}>(pipe(
        tap(({lastUpdate, stationId}) => {
          patchState(store, updateEntity({ id: stationId, changes: { lastUpdate } }, lastUpdateByStationConfig))
        })
      )),
    }
  }),
  withMethods((store) => {
    const measurements = inject(MeasurementsService);
    return {
      connectLastMeasurementsStation: rxMethod<void>(pipe(
        switchMap(() => {
          const afterTimestamp = new Date();
          afterTimestamp.setMinutes(afterTimestamp.getMinutes() - 5);
          const config: MeasurementConfig = {
            ...DEFAULT_MEASUREMENT_CONFIG,
            afterTimestamp,
            frequency: [PERIOD.PT1M],
            sensorType: [SENSOR.WIND_SPEED, SENSOR.WIND_DIRECTION, SENSOR.TEMPERATURE, SENSOR.PRECIPITATION],
            stationId: store.entities().map(station => station.id),
            process: [PROCESS.AVERAGE, PROCESS.SAMPLE],
          };
          return measurements.getLastMeasurements(config);
        }),
        tap((measurements: Measurement[]) => {
          store.entities().forEach(station => {
            const stationMeasurements = measurements.filter(measurement => measurement.stationId === station.id);
            patchState(store, updateEntity(
              { id: station.id, changes: { lastMeasurements: stationMeasurements }},
              measurementsStationConfig
            ));
            const lastUpdate = stationMeasurements.reduce((lastUpdate: DateISOString, measurement: Measurement) => 
              measurement.timestamp > lastUpdate ? measurement.timestamp : lastUpdate, '')
            if (lastUpdate !== '') {
              store.updateLastUpdateValue({lastUpdate: new Date(lastUpdate), stationId: station.id});
            }
          })
        }),
      )),
      observeStationMeasurementUpdated: rxMethod<StationId[]>(pipe(
        tap(stationIds => stationIds.forEach(stationId => store.updateLastUpdateValue({lastUpdate: new Date(), stationId: stationId}))),
      )),
    }
  }),
  withMethods((store) => {
    const stationService = inject(StationsService);
    const parameters = inject(ParametersService);
    const wsService = inject(StationRxStompService);
    return {
      loadStations: rxMethod<void>(pipe(
        switchMap(() => stationService.getStations()),
        switchMap(stations => {
          const stationIds = stations.map(station => station.id);
          const parametersFilter = { stationId: stationIds };
          return parameters.get(parametersFilter).pipe(
            map(stationParameters => {
              return stations.map(station => ({
                ...station,
                parameters: stationParameters[station.id] || [],
              }));
            })
          );
        }),
        tap(stations => {
          return stations.map(station => {
            return {
              ...station,
              measurements: new Array<Measurement>(),
            }
          });
        }),
        tap(stations => {
          patchState(store, setAllEntities(stations.sort(compareStationPriority)));
          patchState(store, setAllEntities(
            stations.map(station => {
              return {
                stationId: station.id, lastUpdate: new Date()
              }
            }),lastUpdateByStationConfig
            
          ));
          patchState(store, setAllEntities(
            stations.map(station => {
              return {
                stationId: station.id, lastMeasurements: new Array<Measurement>()
              }
            }), measurementsStationConfig
          ));
        }),
        tap(() => store.connectLastMeasurementsStation()),
      )),
      connectRealTimeStationStatus: rxMethod<void>(pipe(
        switchMap(() => wsService.connect<StationStatusDataStream>(MESSAGE_TYPE.STATION_STATUS)),
        map(data => data.stationStatus),
        tap(stationStatus => {
          Object.keys(stationStatus).forEach(stationId => {
            patchState(store, updateEntity({
              id: stationId, changes: {
                status: stationStatus[stationId],
              }
            }));
            store.updateLastUpdateValue({lastUpdate: new Date(), stationId});
          });
        }),
      )),
    }
  }),
  withMethods(() => {
    const stationService = inject(StationsService);
    return {
      addStation: rxMethod<StationDTO>(pipe(
        tap(station => stationService.addStation(station))
      )),
      updateStation: rxMethod<StationDTO>(pipe(
        tap(station => stationService.updateStation(station))
      )),
    }
  }),
  withHooks({
    onInit(store) {
      const measurementService = inject(MeasurementsService);
      store.loadStations();
      store.connectRealTimeStationStatus();
      store.observeStationMeasurementUpdated(measurementService.stationMeasurementUpdated);
    },
  })
);

