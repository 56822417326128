import { Injectable, inject } from "@angular/core";
import { Observable, map } from "rxjs";
import { ALERT_SETTING_STATUS, AlertSetting, AlertSettingDto } from "../models/alerts.model";
import { HttpClient } from "@angular/common/http";
import { API_URL } from "src/environments/url";

@Injectable(
    { providedIn: 'root' }
) export class AlertSettingsService {

    http = inject(HttpClient);

    public findAll(): Observable<AlertSetting[]> {
        return this.http.get<AlertSettingDto[]>(`${API_URL.ALERT_SETTINGS}`).pipe(
            mapAlertSettingDtoListToAlertSettingList()
        );
    }

    public update(alertSetting: AlertSetting): Observable<void> {
        const alertSettingBody: AlertSettingDto = fromAlertSettingToAlertSettingDto(alertSetting);
        return this.http.put(`${API_URL.ALERT_SETTINGS}/${alertSetting.id}`, alertSettingBody).pipe(map(() => void 0));
    }

    public create(alertSetting: Omit<AlertSetting, 'id'>): Observable<AlertSetting> {
        const alertSettingBody: Omit<AlertSettingDto, 'id'> = fromAlertSettingToAlertSettingDto(alertSetting);
        return this.http.post<AlertSettingDto>(`${API_URL.ALERT_SETTINGS}`, alertSettingBody).pipe(
            mapAlertSettingDtoToAlertSetting()
        );
    }
}

function fromAlertSettingDtoToAlertSetting(alertSettingDto: AlertSettingDto): AlertSetting {
    return {
        ...alertSettingDto,
        status: alertSettingDto.active ? ALERT_SETTING_STATUS.ACTIVE : ALERT_SETTING_STATUS.INACTIVE,
    }
}


function fromAlertSettingToAlertSettingDto(alertSetting: AlertSetting): AlertSettingDto;
function fromAlertSettingToAlertSettingDto(alertSetting: Omit<AlertSetting, 'id'>): Omit<AlertSettingDto, 'id'>;
function fromAlertSettingToAlertSettingDto(alertSetting: AlertSetting | Omit<AlertSetting, 'id'>): AlertSettingDto | Omit<AlertSettingDto, 'id'> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { ['status']: _ommitedProperty, ...alertSettingDto} = {
        ...alertSetting,
        active: alertSetting.status === ALERT_SETTING_STATUS.ACTIVE,
    };
    if ('id' in alertSetting) {
        return alertSettingDto as AlertSettingDto;
    }
    return alertSettingDto as Omit<AlertSettingDto, 'id'>;
}

function mapAlertSettingDtoToAlertSetting() {
    return function(source: Observable<AlertSettingDto>): Observable<AlertSetting> {
        return source.pipe(
            map(alertSetting => fromAlertSettingDtoToAlertSetting(alertSetting))
        )
    }
}

function mapAlertSettingDtoListToAlertSettingList() {
    return function(source: Observable<AlertSettingDto[]>): Observable<AlertSetting[]> {
        return source.pipe(
            map(alertSettings => alertSettings.map(alertSetting => fromAlertSettingDtoToAlertSetting(alertSetting)))
        )
    }
}
