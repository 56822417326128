import { Component, inject, AfterViewInit, OnDestroy, Output, input, effect } from '@angular/core';
import { DatePipe } from '@angular/common';
import { LatLngBounds, LatLngTuple, Map, map as leafletMap, tileLayer } from 'leaflet';
import { MarkerService } from 'src/app/shared/services/marker.service';
import { Station } from 'src/app/shared/models/stations.models';
import { Subject, combineLatestWith, map } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'dipla-map-content',
  standalone: true,
  imports: [DatePipe],
  template: `
    <div class="map-container">
      <div class="map-frame">
          <div id="map"></div>
      </div>
    </div>
  `,
  styleUrls: ['./map-content.component.scss']
})
export class MapContentComponent implements AfterViewInit, OnDestroy {

  markerService = inject(MarkerService);

  stations = input.required<Array<Station>>();

  #map!: Map;
  #boundsChanged = new Subject<LatLngBounds>();
  #initFitBounds = false;

  @Output()
  stationsInViewChanged = this.#boundsChanged.pipe(
    combineLatestWith(toObservable(this.stations)),
    map(([bounds, stations]) => {
      if (bounds) {
        return stations.filter(station => bounds.contains({ lat: station.latitude, lng: station.longitude }));
      } else {
        return [];
      }
    })
  );

  constructor() {
    effect(() => {
      if(this.#map) {
        this.markerService.makeStationMarkers(this.#map, this.stations());
        if (this.stations().length && !this.#initFitBounds) {
          const pois: LatLngTuple[] = this.stations().map(station => [station.latitude, station.longitude]);
          this.#map.fitBounds(new LatLngBounds(pois));
          this.#initFitBounds = true;
        }
      }
    }, { allowSignalWrites: true })
  }

  private initMap(): void {
    this.#map = leafletMap('map', { 
      center: [ 4.59, 114.59 ],
      zoom: 10
    });

    const tiles = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.#map);
    
    this.#map.on('moveend', () => {
      this.#boundsChanged.next(this.#map.getBounds());
    });
  }

  ngAfterViewInit(): void {
    this.initMap();
    this.markerService.makeStationMarkers(this.#map, this.stations());
  }

  ngOnDestroy(): void {
    this.#map.off();
    this.#map.remove();
  }

}
