import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, Subject, finalize, map, startWith, switchMap, take } from 'rxjs';
import { SOURCE, Source, Station, StationDTO } from '../models/stations.models';
import { API_URL } from 'src/environments/url';

@Injectable({
  providedIn: 'root'
})
export class StationsService {

  http = inject(HttpClient);
  #stationsUpdated = new Subject<void>();

  getStations(): Observable<Station[]> {
    return this.#stationsUpdated.pipe(
      startWith(new Array<Station>()),
      switchMap(() => this.http.get<Station[]>(API_URL.STATIONS)),
      map(stations => stations.map(station => { return {
        ...station,
        media: `img/${station.id}.jpg`
      }}))
    );
  }

  addStation(station: StationDTO): void {
    const body = {
      ...station,
      source: getSourceCode(station.source),
    }
    this.http.post<StationDTO>(API_URL.STATIONS, body).pipe(
      take(1),
      finalize(() => this.#stationsUpdated.next())
    )
    .subscribe();
  }

  updateStation(station: StationDTO): void {
    const body = {
      ...station,
      source: getSourceCode(station.source),
    }
    this.http.put<StationDTO>(`${API_URL.STATIONS}/${station.id}`, body).pipe(
      take(1),
      finalize(() => this.#stationsUpdated.next())
    )
    .subscribe();
  }

}
function getSourceCode(source: Source) {
    let sourceCode = 0;
    switch (source) {
        case SOURCE.AWOS:
            sourceCode = 0;
            break;
        case SOURCE.NAWOS:
            sourceCode = 1;
            break;
        case SOURCE.OTHER:
            sourceCode = 2;
            break;
        default:
            sourceCode = 0;
            break;
    }
    return sourceCode;
}

