import { STATUS, Station } from "../models/stations.models";

const STATION_PRIORITY_BY_STATUS = [
    STATUS.MALFUNCTIONING,
    STATUS.DEGRADED,
    STATUS.NORMAL,
]

export function compareStationPriority(stationA: Station, stationB: Station): number {
    const statusPriority = STATION_PRIORITY_BY_STATUS.indexOf(stationA.status) - STATION_PRIORITY_BY_STATUS.indexOf(stationB.status);
    return statusPriority;
}
