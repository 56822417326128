import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, Component, computed, inject, input, signal } from '@angular/core';

import * as Highcharts from 'highcharts';
import HC_gauge from 'highcharts/highcharts-more';
import { HighchartsChartModule } from 'highcharts-angular';
import { Measurement, PERIOD, PROCESS, UNIT } from 'src/app/shared/models/measurements.models';
import { parseMeasureToStringValue } from 'src/app/shared/utilities/measurement-utilities';
import { SENSOR } from 'src/app/shared/models/stations.models';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { Heading } from 'src/app/shared/models/runway.models';
import { SensorPipe } from 'src/app/shared/pipes/sensor.pipe';
import { UnitMeasurePipe } from 'src/app/shared/pipes/unit-measure.pipe';
import { SensorService } from 'src/app/shared/services/sensor.service';

HC_gauge(Highcharts);
const mainPlotBands = {
  id: 'main',
  from: 0,
  to: 360,
  innerRadius: '100%',
  outerRadius: '90%',
  className: 'main-band',
};
@Component({
  selector: 'dipla-card-met-garden',
  standalone: true,
  imports: [
    CommonModule,
    SensorPipe, UnitMeasurePipe,
    NgTemplateOutlet,
    HighchartsChartModule,
    NgbTooltipModule
  ],
  templateUrl: './card-met-garden.component.html',
  styleUrls: ['./card-met-garden.component.scss'],
})
export class CardMetGardenComponent implements AfterViewInit {

  data = input.required<Array<Measurement>>();
  runwayConfig = input.required<{ heading: Heading, backgroundImage: string }>();

  sensorService = inject(SensorService);
  SENSOR = SENSOR;

  protected updateHighchart = signal(false);
  ngAfterViewInit(): void {
    this.updateHighchart.set(true);
  }


  protected vm$ = computed(() => {
    return {
      sampleOneMinPresentWeather: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.PRESENT_WEATHER && measure.frequency === PERIOD.PT1M && measure.process === PROCESS.SAMPLE
        ), UNIT.CODE),
      averageOneMinBGL: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.BGL && measure.frequency === PERIOD.PT1M && measure.process === PROCESS.AVERAGE
        )),
      sampleTenSecondsQFEInches: parseMeasureToStringValue(this.data()
      .find((measure: Measurement) =>
        measure.sensorType === SENSOR.AIRPORT_LEVEL_PRESSURE_INCHES && measure.frequency === PERIOD.PT10S && measure.process === PROCESS.SAMPLE
      )),
      sampleTenSecondsQFE: parseMeasureToStringValue(this.data()
      .find((measure: Measurement) =>
        measure.sensorType === SENSOR.MEAN_SEA_LEVEL_PRESSURE && measure.frequency === PERIOD.PT10S && measure.process === PROCESS.SAMPLE
      )),
      sampleTenSecondsQNHInches: parseMeasureToStringValue(this.data()
      .find((measure: Measurement) =>
        measure.sensorType === SENSOR.MEAN_SEA_LEVEL_PRESSURE_INCHES && measure.frequency === PERIOD.PT10S && measure.process === PROCESS.SAMPLE
      )),
      sampleTenSecondsQNH: parseMeasureToStringValue(this.data()
      .find((measure: Measurement) =>
        measure.sensorType === SENSOR.AIRPORT_LEVEL_PRESSURE && measure.frequency === PERIOD.PT10S && measure.process === PROCESS.SAMPLE
      )),
      lowCloudLayer: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.LOW_CLOUD_LAYER && measure.frequency === PERIOD.PT30S && measure.process === PROCESS.SAMPLE
        )),
      middleCloudLayer: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.MIDDLE_CLOUD_LAYER && measure.frequency === PERIOD.PT30S && measure.process === PROCESS.SAMPLE
        )),
      upperCloudLayer: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.UPPER_CLOUD_LAYER && measure.frequency === PERIOD.PT30S && measure.process === PROCESS.SAMPLE
        )),
      averageOneMinWindDirection: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.WIND_DIRECTION && measure.frequency === PERIOD.PT1M && measure.process === PROCESS.AVERAGE
        )),
      averageOneMinWindSpeed: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.WIND_SPEED && measure.frequency === PERIOD.PT1M && measure.process === PROCESS.AVERAGE
        )),
      averageTenMinWindDirection: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.WIND_DIRECTION && measure.frequency === PERIOD.PT10M && measure.process === PROCESS.AVERAGE
        )),
      averageOneMinMOR: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.MOR && measure.frequency === PERIOD.PT1M && measure.process === PROCESS.AVERAGE
        )),
      averageTenMinMOR: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.MOR && measure.frequency === PERIOD.PT10M && measure.process === PROCESS.AVERAGE
        )),
      averageOneMinRVR: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.RUNWAY_VISUAL_RANGE && measure.frequency === PERIOD.PT1M && measure.process === PROCESS.AVERAGE
        )),
      averageTenMinRVR: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.RUNWAY_VISUAL_RANGE && measure.frequency === PERIOD.PT10M && measure.process === PROCESS.AVERAGE
        )),
      averageTenMinWindSpeed: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.WIND_SPEED && measure.frequency === PERIOD.PT10M && measure.process === PROCESS.AVERAGE
        )),
      maximumTenMinWindDirection: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.WIND_DIRECTION && measure.frequency === PERIOD.PT10M && measure.process === PROCESS.MAXIMUM
        )),
      minimumTenMinWindDirection: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.WIND_DIRECTION && measure.frequency === PERIOD.PT10M && measure.process === PROCESS.MINIMUM
        )),
      maximumTenMinWindSpeed: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.WIND_SPEED && measure.frequency === PERIOD.PT10M && measure.process === PROCESS.MAXIMUM
        )),
      minimumTenMinWindSpeed: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.WIND_SPEED && measure.frequency === PERIOD.PT10M && measure.process === PROCESS.MINIMUM
        )),
      averageOneMinAirTemperature: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.TEMPERATURE && measure.frequency === PERIOD.PT1M && measure.process === PROCESS.AVERAGE
        )),
      averageTenMinAirTemperature: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.TEMPERATURE && measure.frequency === PERIOD.PT10M && measure.process === PROCESS.AVERAGE
        )),
      averageOneMinDewPoint: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.DEW_POINT && measure.frequency === PERIOD.PT1M && measure.process === PROCESS.AVERAGE
        )),
      averageTenMinDewPoint: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.DEW_POINT && measure.frequency === PERIOD.PT10M && measure.process === PROCESS.AVERAGE
        )),
      averageOneMinRelativeHumidity: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.RELATIVE_HUMIDITY && measure.frequency === PERIOD.PT1M && measure.process === PROCESS.AVERAGE
        )),
      averageTenMinRelativeHumidity: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.RELATIVE_HUMIDITY && measure.frequency === PERIOD.PT10M && measure.process === PROCESS.AVERAGE
        )),
      averageOneMinQNH: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.MEAN_SEA_LEVEL_PRESSURE && measure.frequency === PERIOD.PT1M && measure.process === PROCESS.AVERAGE
        )),
      averageTenMinQNH: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.MEAN_SEA_LEVEL_PRESSURE && measure.frequency === PERIOD.PT10M && measure.process === PROCESS.AVERAGE
        )),
      totalOneMinPrecipitation: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.PRECIPITATION && measure.frequency === PERIOD.PT1M && measure.process === PROCESS.SAMPLE
        )),
      totalOneHourPrecipitation: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.PRECIPITATION && measure.frequency === PERIOD.PT1H && measure.process === PROCESS.SAMPLE
        )),
      crossWindComponent: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.CROSS_WIND_COMPONENT && measure.frequency === PERIOD.PT2M && measure.process === PROCESS.AVERAGE
        ), UNIT.CROSS_WIND_COMPONENT),
      trackWindComponent: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.TRACK_WIND_COMPONENT && measure.frequency === PERIOD.PT2M && measure.process === PROCESS.AVERAGE
        ), UNIT.CROSS_WIND_COMPONENT),
        averageOneMinSolarRadiation: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.SOLAR_RADIATION && measure.frequency === PERIOD.PT1M && measure.process === PROCESS.AVERAGE
        )),
        totalOneHourSolarRadiation: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.SOLAR_RADIATION && measure.frequency === PERIOD.PT1H && measure.process === PROCESS.SAMPLE
        )),
        totalOneHourSolarDuration: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.SOLAR_DURATION && measure.frequency === PERIOD.PT1H && measure.process === PROCESS.SAMPLE
        )),
        totalOneDaySolarDuration: parseMeasureToStringValue(this.data()
        .find((measure: Measurement) =>
          measure.sensorType === SENSOR.SOLAR_DURATION && measure.frequency === PERIOD.PT24H && measure.process === PROCESS.SAMPLE
        )),
    }
  });

  protected highcharts: typeof Highcharts = Highcharts;
  protected chartOptions = computed<Highcharts.Options>(() => {
    let chartOptions = this.getInitChartOptions();
    chartOptions.series?.forEach(series => {
      if (series.type === 'gauge') {
        let value: number | string | undefined;
        switch (series.id) {
          case 'tenMinDirection':
            value = this.data()
              .find((measure: Measurement) =>
                measure.sensorType === SENSOR.WIND_DIRECTION && measure.frequency === PERIOD.PT10M && measure.process === PROCESS.AVERAGE
              )?.value;
            series.data = [Number.parseFloat('' + value)];
            break;
          case 'oneMinDirection':
            value = this.data()
              .find((measure: Measurement) =>
                measure.sensorType === SENSOR.WIND_DIRECTION && measure.frequency === PERIOD.PT1M && measure.process === PROCESS.AVERAGE
              )?.value;
            series.data = [Number.parseFloat('' + value)];
            break;
          default: break;
        }
      }
    })
    chartOptions = {
      ...chartOptions,
      yAxis: {
        ...chartOptions.yAxis,
        plotBands: [
          mainPlotBands,
          {
            id: 'tenMinutesMinMaxBand',
            from: this.data()
              .find((measure: Measurement) =>
                measure.sensorType === SENSOR.WIND_DIRECTION && measure.frequency === PERIOD.PT10M && measure.process === PROCESS.MINIMUM
              )?.value as number || 0,
            to: this.data()
              .find((measure: Measurement) =>
                measure.sensorType === SENSOR.WIND_DIRECTION && measure.frequency === PERIOD.PT10M && measure.process === PROCESS.MAXIMUM
              )?.value as number || 0,
            innerRadius: '98%',
            outerRadius: '90%',
            className: 'ten-min-band',
          }
        ]
      },
    };
    return chartOptions;
  });

  private getInitChartOptions(): Highcharts.Options {
    return {
      chart: {
        styledMode: true,
        type: 'gauge',
      },

      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },

      pane: {
        startAngle: 0,
        endAngle: 360,
      },

      yAxis: [
        {
          min: 0,
          max: 360,
          lineWidth: 2,

          minorTickInterval: 10,
          minorTickLength: 10,
          minorTickPosition: 'inside',

          tickInterval: 30,
          tickPosition: 'inside',
          tickLength: 10,

          labels: {
            step: 1,
            distance: 18,
          },
          plotBands: [
            mainPlotBands,
            {
              id: 'tenMinutesMinMaxBand',
              from: 0,
              to: 0,
              innerRadius: '98%',
              outerRadius: '90%',
              className: 'ten-min-band',
            },
          ],
        },
      ],

      plotOptions: {
        gauge: {
          pivot: {
            radius: 2,
          },
        },
      },

      series: [
        {
          name: '10-MIN Direction',
          id: 'tenMinDirection',
          animation: false,
          type: 'gauge',
          tooltip: {
            valueSuffix: ' °',
            followPointer: true,
          },
          dial: {
            baseLength: '65%',
            baseWidth: 1,
            rearLength: '-65%',
            topWidth: 20,
            borderWidth: 0,
          },
        },
        {
          name: '1-MIN Direction',
          id: 'oneMinDirection',
          type: 'gauge',
          animation: false,
          tooltip: {
            valueSuffix: ' °',
            followPointer: true,
          },
          dial: {
            path: [
              ['M', 0, 0],
              ['h', -40],
              ['l', 3, 5],
              ['l', -10, -5],
              ['l', 10, -5],
              ['l', -3, 5],
              ['h', 90],
              ['l', 5, -5],
              ['h', 13],
              ['l', -5, 5],
              ['l', 5, 5],
              ['h', -13],
              ['l', -5, -5],
              ['h', -50],
            ]
          },
        },
      ],
    };
  }
}
