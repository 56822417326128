export const environment = {
    production: true,

    HOME_URL: window?.location.origin || '/',
    BASE_API_URL: '/api',
    BASE_WS_URL: (window?.location.protocol === 'https:' ? 'wss://' : 'ws://') + window?.location.hostname + '/ws' || '/ws',

    // Keycloak
    KEYCLOAK_URL: '/auth',
    KEYCLOAK_REALM: 'client-users',
    KEYCLOAK_CLIENT_ID: 'dipla-client-users',
};