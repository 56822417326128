import { Pipe, PipeTransform } from '@angular/core';
import { Sensor } from '../models/stations.models';
import { Measurement, StationMeasurement } from '../models/measurements.models';
import { parseMeasureToStringValue } from '../utilities/measurement-utilities';

@Pipe({
  name: 'valueUnitMeasure',
  standalone: true
})
export class ValueUnitMeasurePipe implements PipeTransform {

  transform(measure: Measurement | StationMeasurement, sensorCode: string, sensors: Sensor[]): string {
    const unit = sensors.find(sensor => sensor.type === sensorCode)?.unit;
    return parseMeasureToStringValue(measure, unit);
  }

}
