import { Routes } from '@angular/router';
import { MapComponent } from './map/map.component';
import { AwosComponent } from './awos/awos.component';
import { AlertsComponent } from './alerts/alerts.component';
import { StationsComponent } from './stations/stations.component';

export const routes: Routes = [
    { path: '',   redirectTo: '/map', pathMatch: 'full' },
    {
        path: 'map',
        component: MapComponent,
    },
    {
        path: 'awos',
        component: AwosComponent,
    },
    {
        path: 'alerts',
        component: AlertsComponent,
    },
    {
        path: 'stations',
        component: StationsComponent,
    },
];
