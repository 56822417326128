import { Pipe, PipeTransform } from '@angular/core';
import { Sensor } from '../models/stations.models';

@Pipe({
  name: 'sensor',
  standalone: true
})
export class SensorPipe implements PipeTransform {

  transform(sensorCode: string, sensors: Sensor[]): string {
    return sensors.find(sensor => sensor.type === sensorCode)?.label || '';
  }

}
