import { DatePipe, NgClass } from '@angular/common';
import { Component, Signal, computed, inject, viewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIcon } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';

import { AlertData, ThresholdAlert } from 'src/app/shared/models/alerts.model';
import { AlertStore } from '../../shared/services/alerts-store';
import { customFilterPredicate } from 'src/app/shared/utilities/mat-table-utilities';
import { MatDialog } from '@angular/material/dialog';
import { DialogStationComponent } from 'src/app/map/dialog-station/dialog-station.component';
import { tap } from 'rxjs';
import { ParameterCriteria } from 'src/app/shared/models/measurements.models';
import { isThresholdAlert } from 'src/app/shared/utilities/alerts-utilities';
import { IsThresholdAlertPipe } from 'src/app/shared/pipes/is-threshold-alert.pipe';
import { StationStore } from 'src/app/shared/services/stations-store';

@Component({
  selector: 'dipla-alerts-table',
  standalone: true,
  imports: [
    DatePipe, IsThresholdAlertPipe,
    NgClass,
    MatTableModule, MatSortModule, MatPaginatorModule,
    MatIcon, MatTooltip, MatButtonModule, MatProgressSpinnerModule,
  ],
  templateUrl: './alerts-table.component.html',
  styleUrl: './alerts-table.component.scss',
})
export class AlertsTableComponent {

  alertStore = inject(AlertStore);
  dialog = inject(MatDialog);
  stationStore = inject(StationStore);

  alertDataTable = this.alertStore.alertDataTable;
  loading = this.alertStore.loading;
  filterValue = this.alertStore.filterTerm;

  displayedColumns: string[] = ['message', 'type', 'status', 'startTimestamp', 'endTimestamp', 'stationName', 'acknowledged'];
  
  paginator = viewChild.required(MatPaginator);
  sort = viewChild.required(MatSort);
  dataSource:Signal<MatTableDataSource<AlertData>> = computed(() => {
    const dataSource = new MatTableDataSource<AlertData>(this.alertDataTable());
    dataSource.paginator = this.paginator();
    dataSource.sort = this.sort();
    dataSource.filterPredicate = customFilterPredicate;
    dataSource.filter = this.filterValue().trim().toLowerCase();
    return dataSource;
  })

  onAcknowledgeAlert(alertData: AlertData) {
    this.alertStore.acknowledgeAlert(alertData.id);
  }

  onAcknowledgeAllAlerts(event: MouseEvent) {
    event.stopPropagation();
    this.alertStore.acknowledgeAllAlerts();
  }

  onAlertClick(alert: ThresholdAlert){
    const alertEntity = this.alertStore.alertsEntityMap()[alert.id];
    if (isThresholdAlert(alertEntity)) {
      const station = this.stationStore.stations().find(station => alert.stationId === station.id);
      const parameters: Array<ParameterCriteria> = [{
        sensorType: alertEntity.sensorType,
        process: alertEntity.process,
        frequency: alertEntity.frequency,
      }];
      const stationDialogRef = this.dialog.open(DialogStationComponent, {
        height: '90%',
        width: '90%',
        maxWidth: '100%',
        data: {
          station,
          parameters,
        }
      });
      stationDialogRef.afterClosed().pipe(
        tap(() => stationDialogRef.close()),
      ).subscribe();
    }
  }
}
